import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton, MenuItem, Tooltip } from "@mui/material";
import { indigo } from "@mui/material/colors";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ButtonUI } from "../ui/button";
import { initialJobsFilters } from "../../helper/constants";

const TYPE = {
  SORT: "SORT",
  FILTER: "FILTER",
};

const FILTERIDS = {
  DEPT: "depId",
  PRIORITY: "priorityId",
  FACILITY: "facility",
  STATUS: "status",
};

const FILTER_BY_OPTIONS = [
  {
    id: "DEPT",
    name: "Department",
  },
  {
    id: "PRIORITY",
    name: "Priority",
  },
  {
    id: "FACILITY",
    name: "Facility",
  },
  {
    id: "STATUS",
    name: "Status",
  },
  {
    id: "COMPANY",
    name: "Company",
  },
];

const theme = createTheme({});

export const JobsSortFilter = ({
  statusOptions,
  facilityOptions,
  priorityOptions,
  deptOptions,
  handleSortFilter,
  appliedFilters,
  setAppliedFilters,
  companyOptions,
  selfCompanyData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(initialJobsFilters);
  const [currentFilterOption, setCurrentFilterOption] = useState(
    FILTER_BY_OPTIONS[0]
  );

  useEffect(() => {
    setFilter(appliedFilters);
  }, [open]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleFilters = (el, name) => {
    setFilter({ ...filter, [name]: el });
  };

  const handleApplyFilters = () => {
    setAppliedFilters(filter);
    handleSortFilter(filter);
    handleClose();
  };

  const handleResetFilters = () => {
    setAppliedFilters(initialJobsFilters);
    handleSortFilter(initialJobsFilters);
    handleClose();
  };

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Tooltip placement="top" title="Sort & Filter">
          <div className="position-relative">
            <IconButton
              size="small"
              id="menubutton22"
              aria-owns={open ? "simple-menu2" : null}
              aria-haspopup="true"
              onClick={handleOpen}
              className="filter-sort-icon"
            >
              <FilterListIcon sx={{ color: indigo[500] }} />
            </IconButton>
            {appliedFilters !== initialJobsFilters && (
              <div className="filter-dot" />
            )}
          </div>
        </Tooltip>
        <Menu
          id="simple-menu2"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={handleClose}
        >
          <div className="filter-menu-container">
            <div className="filter-main-menu">
              <div className="main-filter-heading">Filter By</div>
              <div style={{ paddingLeft: 4 }}>
                {FILTER_BY_OPTIONS.map((el) => (
                  <MenuItem
                    sx={{
                      backgroundColor:
                        el.id === currentFilterOption.id &&
                        "rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={() => setCurrentFilterOption(el)}
                  >
                    <div
                      className={
                        filter[FILTERIDS[el.id]]
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    ></div>
                    {el.name}
                  </MenuItem>
                ))}
              </div>
              <div className="main-filter-heading">Sort By</div>
              <div style={{ paddingLeft: 4, overflowY: "auto" }}>
                <MenuItem onClick={() => handleFilters(null, "sort")}>
                  <div
                    className={
                      !filter.sort ? "dot-div selected" : "dot-div filter"
                    }
                  ></div>
                  Default
                </MenuItem>
                <MenuItem onClick={() => handleFilters("days", "sort")}>
                  <div
                    className={
                      filter.sort === "days"
                        ? "dot-div selected"
                        : "dot-div filter"
                    }
                  ></div>
                  Days Posted
                </MenuItem>
                <MenuItem onClick={() => handleFilters("date", "sort")}>
                  <div
                    className={
                      filter.sort === "date"
                        ? "dot-div selected"
                        : "dot-div filter"
                    }
                  ></div>
                  Date
                </MenuItem>
              </div>
            </div>
            <div className="sub-main-filter-container">
              {/* <MenuItem
                onClick={() =>
                  handleFilters(null, FILTERIDS[currentFilterOption.id])
                }
              >
                <div
                  className={
                    !filter[FILTERIDS[currentFilterOption.id]]
                      ? "dot-div selected"
                      : "dot-div filter"
                  }
                ></div>
                All
              </MenuItem> */}
              {currentFilterOption.id === "FACILITY" &&
                facilityOptions.map((facility) => (
                  <>
                    {facility.address !== "" && (
                      <MenuItem
                        key={facility.facilitiesId}
                        onClick={() =>
                          handleFilters(facility.facilitiesId, "facility")
                        }
                      >
                        <div
                          className={
                            facility.facilitiesId === filter.facility //byBrand
                              ? "dot-div selected"
                              : "dot-div filter"
                          }
                        />
                        {facility.address}
                      </MenuItem>
                    )}
                  </>
                ))}
              {currentFilterOption.id === "STATUS" &&
                statusOptions.map((status) => (
                  <MenuItem
                    key={status.description}
                    onClick={() => handleFilters(status.id, "status")}
                  >
                    <div
                      className={
                        status.id === filter.status
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    />
                    {status.description}
                  </MenuItem>
                ))}
              {currentFilterOption.id === "PRIORITY" &&
                priorityOptions.map((p) => (
                  <MenuItem
                    onClick={() => handleFilters(p.id, "priorityId")}
                    key={p.id}
                  >
                    <div
                      className={
                        p.id === filter.priorityId
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    ></div>
                    {p.description}
                  </MenuItem>
                ))}
              {currentFilterOption.id === "DEPT" &&
                deptOptions.map((p) => (
                  <MenuItem
                    onClick={() => handleFilters(p.departmentId, "depId")}
                    key={p.departmentId}
                  >
                    <div
                      className={
                        p.departmentId === filter.depId
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    ></div>
                    {p.name}
                  </MenuItem>
                ))}
              {currentFilterOption.id === "COMPANY" &&
                companyOptions.map((company) => (
                  <MenuItem
                    key={company.description}
                    onClick={() =>
                      handleFilters(company.companyId, "companyId")
                    }
                  >
                    <div
                      className={
                        company.companyId === filter.companyId
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    />
                    {company.name}
                  </MenuItem>
                ))}
              <MenuItem
                key={selfCompanyData.companyId}
                onClick={() =>
                  handleFilters(selfCompanyData.companyId, "companyId")
                }
              >
                <div
                  className={
                    selfCompanyData.companyId === filter.companyId
                      ? "dot-div selected"
                      : "dot-div filter"
                  }
                />
                {selfCompanyData.companyName}
              </MenuItem>
            </div>
          </div>
          <div className="filter-btn-main-container">
            <div className="filter-btns">
              <ButtonUI
                p={8}
                width={"60%"}
                color={"#3954A5"}
                variant="outlined"
                onClick={handleResetFilters}
                disabled={appliedFilters === initialJobsFilters}
              >
                RESET
              </ButtonUI>
            </div>
            <div className="filter-btns">
              <ButtonUI
                p={8}
                width={"60%"}
                variant={
                  filter === initialJobsFilters ? "outlined" : "contained"
                }
                onClick={handleApplyFilters}
                disabled={filter === initialJobsFilters}
              >
                APPLY
              </ButtonUI>
            </div>
          </div>
        </Menu>
      </ThemeProvider>
    </div>
  );
};
