import { JobSelectForm } from "./jobSelectForm";
import { JobInfoCard } from "../../../../cards/JobInfoCard";
import { useState } from "react";
import { useEffect } from "react";
import Api from "../../../../../../helper/Api";
import { ReviewingStatus } from "./Reviewing";
import { InterviewingStatus } from "./Interviewing";
import { VideoInterviewStatus } from "./VideoInterview";
import { OfferingStatus } from "./Offering/index";
import { OfferLetterStatus } from "./OfferLetter";
import { PreHire } from "./PreHire";
import DoneIcon from "@mui/icons-material/Done";
import { Tooltip } from "@mui/material";
import { OnBoarding } from "./OnBoarding";
import { HireStatus } from "./Hire";
import { APPLICANT_DATA_ACTIONS } from "../../../../../../redux/applicantData/actions";
import { useDispatch } from "react-redux";
import { EclipseLoader } from "../../../../eclipseLoader";
import { AlertBox } from "../../../../../../helper/utils";
import { useSelector } from "react-redux";
import { StopApplicantForm } from "./stopApplicantForm";
import { validations } from "../../../../../../helper/validations";
import { toast } from "react-toastify";
import { ButtonUI } from "../../../../../ui/button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const jobCStatus = {
  WorkstepNewApplicant: "",
  WorkstepVideoInterview: "VIDEO_INTERVIEW",
  WorkstepInterviewing: "INTERVIEWING",
  WorkstepOffering: "OFFERING",
  WorkstepLOI: "OFFER_LETTER",
  WorkstepPreHire: "PREHIRE",
  WorkstepOnboarding: "ONBOARDING",
  WorkstepHire: "HIRE",
  VIDEO_INTERVIEW: "VIDEO_INTERVIEW",
  INTERVIEWING: "INTERVIEWING",
  OFFERING: "OFFERING",
  OFFER_LETTER: "OFFER_LETTER",
  PREHIRE: "PREHIRE",
  ONBOARDING: "ONBOARDING",
  HIRE: "HIRE",
  REVIEWING: "REVIEWING",
};

const color = {
  ON_HOLD: "red",
  ACTIVE: "active",
  CANCELLED: "",
  FILLED: "brown",
  HIRING: "lblue",
  PENDING: "yellow",
};

export const ApplicantJobData = ({
  applicantData,
  selectedSuitCase,
  selectedStage,
  setSelectedStage,
  getApplicantJobData,
}) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [jobCycleStatusList, setJobCycleStatusList] = useState([]);
  const [resendForm, setResendForm] = useState(false);
  const [resendInterviewForm, setResendInterviewForm] = useState(false);
  const [workStepData, setWorkStepData] = useState([]);
  const [jobStepData, setJobStepData] = useState([]);
  const [isUpdated, setUpdated] = useState(true);
  const [isLoader, setLoader] = useState(false);
  const [openStopAppForm, setOpenStopAppForm] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const errors = {};
  const { isApplicantRefresh } = useSelector(
    (state) => state.applicantDataReducer
  );

  useEffect(() => {
    Api("GET", "Job/GetJobCycleStatus").then((res) => {
      res.data.shift();
      setJobCycleStatusList(res.data);
    });
  }, []);

  useEffect(() => {
    if (selectedSuitCase?.jobApplicantId !== undefined) {
      getWorkStepDetails();
    }
  }, [selectedSuitCase]);

  useEffect(() => {
    if (
      isApplicantRefresh &&
      selectedSuitCase?.jobCycleStatus === "NEW_APPLICATION"
    ) {
      traverseJobData("REVIEWING");
      setSelectedStage("REVIEWING");
      dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
    }
  }, [isApplicantRefresh]);

  const getWorkStepDetails = () => {
    Api(
      "GET",
      `Job/ViewApplicantWorkStepDetails?jobApplicantId=${selectedSuitCase?.jobApplicantId}`
    ).then((res) => {
      res.data.shift();
      const data = [];
      res.data.forEach((d) => {
        data.push(d.stepStatus);
      });
      setJobStepData(data);
      setWorkStepData(res.data);
    });
  };

  function changeJobCycleStatus(status, currValue) {
    if (status === "STOP") {
      toggleStopAppForm(true);
      setFormValues({});
      setFormErrors({});
      return;
    }

    const recs = [...jobCycleStatusList];

    let currValueSequence = 0;
    let upcomingSequence = 0;

    recs.map((r) => {
      if (r.id === status) {
        upcomingSequence = r.order;
      }

      if (r.id === currValue) {
        currValueSequence = r.order;
      }
    });

    if (upcomingSequence < currValueSequence) {
      traverseJobData(status);
      return;
    }

    AlertBox().then((res) => {
      if (!res.isConfirmed) return;
      setLoader(true);
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${selectedSuitCase.jobId}&applicantId=${applicantData.applicantId}&status=${status}`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
            getApplicantJobData(selectedSuitCase?.jobId);
            setSelectedStage(status);
            setUpdated(true);
          }
        })
        .finally(() => setLoader(false));
    });
  }

  const traverseJobData = (status) => {
    setUpdated(false);
    const data = [...workStepData];
    data.map((d, index) => {
      if (d.stepStatus === "Current") {
        d.stepStatus =
          jobStepData[index] === "Current" ? "Pending" : jobStepData[index];
      }

      if (d.name === status || d.component === status) {
        d.stepStatus = "Current";
      }
    });
    const record = { ...selectedSuitCase };
    record.jobCycleStatus = jobCStatus[status];
    setSelectedStage(jobCStatus[status]);
    setWorkStepData(data);
  };

  const toggleStopAppForm = (open) => {
    setOpenStopAppForm(open);
  };

  const saveStopApplicant = () => {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    setSubmitting(true);
    const params = {
      companyId: applicantData.companyId,
      applicantId: applicantData.applicantId,
      jobId: selectedSuitCase?.jobId,
      stopReasonsId: formValues.stopReasonsId,
      stopReasonsChatMessage: formValues.stopReasonsChatMessage,
      stopReasonsNotes: formValues.stopReasonsNotes,
    };

    Api("POST", `Job/StopApplication`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          toggleStopAppForm(false);
          getApplicantJobData(selectedSuitCase?.jobId);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Validations
  const validate = (values) => {
    if (!values.stopReasonsId) {
      errors.stopReasonsId = validations?.stopReasonsId?.req;
    }

    return errors;
  };

  const restartApplication = () => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      const params = {
        companyId: applicantData.companyId,
        applicantId: applicantData.applicantId,
        jobId: selectedSuitCase?.jobId,
      };

      Api("POST", `Job/StartApplication`, params).then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          getApplicantJobData(selectedSuitCase?.jobId);
        } else {
          toast.error(res.data.message);
        }
      });
    });
  };

  return (
    <div className="job-data-stages">
      {openStopAppForm === true && (
        <StopApplicantForm
          applicantId={applicantData.applicantId}
          openForm={openStopAppForm}
          toggleForm={toggleStopAppForm}
          setOpenForm={setOpenStopAppForm}
          formValues={formValues}
          setFormValues={setFormValues}
          companyId={applicantData.companyId}
          isSubmitting={isSubmitting}
          handleSubmit={saveStopApplicant}
          formErrors={formErrors}
        />
      )}

      <div className="d-flex justify-content-between align-items-center p-0-16">
        <strong className="font-12">{selectedSuitCase?.jobNo}</strong>
        <span className={`status-label ${color[selectedSuitCase?.jobStatus]}`}>
          {"Job " + selectedSuitCase?.jobStatus}
        </span>
      </div>

      <div className="mt-10 d-flex flex-column p-0-16 border-bottom">
        <strong className="font-14">{selectedSuitCase?.jobName}</strong>
        <span className="font-12 mt-1">
          {localStorage.getItem("companyName")}
        </span>
      </div>

      {selectedSuitCase && (
        <div className="font-12 p-16">
          {selectedSuitCase?.isStopApplication && (
            <>
              <strong>Chat Message Sent</strong>
              <span> {selectedSuitCase?.stopReasonMessage}</span>
              <span className="mt-10">
                {selectedSuitCase?.stopReasonNotes}
              </span>
              <div className="mt-10">
                <ButtonUI
                  p="6px"
                  color="#3954A5"
                  width="313px"
                  variant="outlined"
                  onClick={restartApplication}
                  rightIcon={<RestartAltIcon fontSize="small" />}
                >
                  Restart this Application
                </ButtonUI>
              </div>
            </>
          )}
        </div>
      )}

      {selectedSuitCase && (
        <div className="job-status font-14 mt-10 mb-10 p-0-16">
          <strong className="font-blue">Status:</strong>
          <div className="forms-input curr-status">
            {selectedSuitCase["jobCycleStatus"] && (
              <select
                value={selectedSuitCase["jobCycleStatus"]}
                onChange={(e) =>
                  changeJobCycleStatus(
                    e.target.value,
                    selectedSuitCase["jobCycleStatus"]
                  )
                }
              >
                {jobCycleStatusList.map((status) => (
                  <option key={status?.id} value={status?.id}>
                    {status?.description}
                  </option>
                ))}
                <option value="STOP">Stop Applicant</option>
              </select>
            )}
          </div>
        </div>
      )}

      {isLoader === true ? (
        <EclipseLoader />
      ) : (
        <div className="p-0-16">
          <div className="d-flex flex-column">
            <div className="d-flex">
              <Tooltip title={"Go to New Application"} placement="top">
                <div
                  key="NEW_APPLICATION"
                  className="check-sections grey pointer"
                ></div>
              </Tooltip>
              {workStepData.map((status, index) => (
                <Tooltip title={"Go to " + status.name} placement="top">
                  <div
                    key={status.stepStatus + index}
                    className={
                      status.stepStatus === "Complete"
                        ? "check-sections green pointer"
                        : status.stepStatus === "Current"
                        ? "check-sections blue pointer"
                        : "check-sections grey pointer"
                    }
                    onClick={() => traverseJobData(status.component)}
                  >
                    {status.stepStatus === "Complete" ||
                      (status.stepStatus === "Current" && (
                        <DoneIcon sx={{ color: "#FFFF" }} fontSize="small" />
                      ))}
                  </div>
                </Tooltip>
              ))}
            </div>

            {selectedSuitCase && selectedSuitCase.isStopApplication && (
              <div className="mb-10">
                <span className="status-label">
                  Ineligible : {selectedSuitCase.stopReasonName}
                </span>
              </div>
            )}
          </div>

          {selectedSuitCase && (
            <>
              {(selectedStage === "REVIEWING" ||
                (selectedSuitCase["jobCycleStatus"] === "REVIEWING" &&
                  isUpdated === true)) && (
                <ReviewingStatus
                  selectedSuitCase={selectedSuitCase}
                  getApplicantJobData={getApplicantJobData}
                  applicantId={applicantData.applicantId}
                  companyId={applicantData.companyId}
                />
              )}

              {(selectedStage === "VIDEO_INTERVIEW" ||
                (selectedSuitCase["jobCycleStatus"] === "VIDEO_INTERVIEW" &&
                  isUpdated === true)) && (
                <VideoInterviewStatus
                  setResendInterviewForm={setResendInterviewForm}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  selectedSuitCase={selectedSuitCase}
                  resendForm={resendForm}
                  resendInterviewForm={resendInterviewForm}
                  applicantData={applicantData}
                />
              )}

              {(selectedStage === "INTERVIEWING" ||
                (selectedSuitCase["jobCycleStatus"] === "INTERVIEWING" &&
                  isUpdated === true)) && (
                <InterviewingStatus
                  selectedSuitCase={selectedSuitCase}
                  applicantId={applicantData.applicantId}
                />
              )}

              {(selectedStage === "OFFERING" ||
                (selectedSuitCase["jobCycleStatus"] === "OFFERING" &&
                  isUpdated === true)) && (
                <OfferingStatus
                  selectedSuitCase={selectedSuitCase}
                  applicantId={applicantData.applicantId}
                />
              )}

              {(selectedStage === "OFFER_LETTER" ||
                (selectedSuitCase["jobCycleStatus"] === "OFFER_LETTER" &&
                  isUpdated === true)) && (
                <OfferLetterStatus
                  selectedSuitCase={selectedSuitCase}
                  applicantId={applicantData.applicantId}
                  applicantName={applicantData.name}
                />
              )}

              {(selectedStage === "PREHIRE" ||
                (selectedSuitCase["jobCycleStatus"] === "PREHIRE" &&
                  isUpdated === true)) && (
                <PreHire
                  selectedSuitCase={selectedSuitCase}
                  applicantId={applicantData.applicantId}
                />
              )}

              {(selectedStage === "ONBOARDING" ||
                (selectedSuitCase["jobCycleStatus"] === "ONBOARDING" &&
                  isUpdated === true)) && (
                <OnBoarding
                  selectedSuitCase={selectedSuitCase}
                  applicantId={applicantData.applicantId}
                />
              )}

              {(selectedStage === "HIRE" ||
                (selectedSuitCase["jobCycleStatus"] === "HIRE" &&
                  isUpdated === true)) && (
                <HireStatus
                  selectedSuitCase={selectedSuitCase}
                  applicantId={applicantData.applicantId}
                />
              )}
            </>
          )}
          {/* <p className="font-14">
            <strong>
              {selectedSuitCase["jobName"]} ({selectedSuitCase["jobNo"]})
            </strong>
          </p> */}
          {/* {applicantInfo?.map((job) => (
            <JobInfoCard
              key={job.jobId}
              title={job.jobName}
              jobDescription={job.jobDescription}
              jobStatus={job.jobStatus}
              jobNo={job.jobNo}
            />
          ))} */}
        </div>
      )}
    </div>
  );
};
