import { amber, indigo, red } from "@mui/material/colors";
import BackgroundLetterAvatars from "../../../../BackgroundLetterAvatars";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Tooltip,
  Rating,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from '@mui/icons-material/Create';
import "../../../../../../../assets/css/components/company-detail/_video-col.css";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { VIDEO_DATA_ACTIONS } from "../../../../../../../redux/videoInterviewData/actions";
import { useDispatch } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import { ButtonUI } from "../../../../../../ui/button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import CancelQuestionBox from "../../../../../dialogBox/cancelQuestionBox";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { baseUrl } from "../../../../../../../helper/constants";

const QUE_STATUS = {
  Pending: "status-label red",
  Complete: "status-label active",
  Completed: "status-label active",
  Cancel: "status-label",
  Canceled: "status-label",
  Cancelled: "status-label",
};

export const VideoInterviewColumn = ({ interview }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [openRedoForm, setRedoForm] = useState(false);
  const [openRatingForm, setRatingForm] = useState(false);
  const [videoQueId, setVideoQueId] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [lRating, setLRating] = useState(0);
  const [reviewers, setReviewers] = useState([]);
  const [interviewData, setInterviewData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingReview, setEditingReview] = useState({});
  const [isRedoSubmitting, setRedoSubmitting] = useState(false);
  const [isRatingSubmitting, setRatingSubmitting] = useState(false);

  const myRef = useRef(null);
  const { videosArray } = useSelector(
    (state) => state.videoInterviewDataReducer
  );

  const isUserLoggedIn = localStorage.getItem("user");

  useEffect(async () => {
    var the_arr = baseUrl.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    connect.on("SingleVIRequestData", (res) => {
      setInterviewData(res);
    });

    await connect.start();

  }, []);

  useEffect(() => {
    getVIData();
  }, []);

  const getVIData = () => {
    Api("GET", `Job/GetVIRequestData?companyId=${interview.companyId}&applicantId=${interview.applicantId}&jobId=${interview.jobId}&jobApplicantVideoInterviewId=${interview.jobApplicantVideoInterviewId}`)
      .then((res) => {
        setInterviewData(res.data);
      })
      .catch((err) => console.log(err));
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const closeApplicantTab = () => {
    const dataArr = [...videosArray];
    dataArr.forEach((rec, index) => {
      if (
        rec.jobApplicantVideoInterviewId ===
        interview.jobApplicantVideoInterviewId
      ) {
        dataArr.splice(index, 1);
      }
    });

    setTimeout(() => {
      dispatch({
        type: VIDEO_DATA_ACTIONS.SET_VIDEOS_ARRAY,
        payload: dataArr,
      });
    }, 500);
  };

  const userHasReviewedCurrentVideo = interviewData.some(
    (review) => review.userId === isUserLoggedIn
  );

  const rateVideo = (queId) => {
    setRatingForm(true);
    setFormValues({});
    setVideoQueId(queId);
  };

  const redoRequest = (videoQuestionId) => {
    toggleRedoForm(true);
    setVideoQueId(videoQuestionId);
    setTimeout(() => {
      myRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }, 200);
  };

  const toggleRedoForm = (open) => {
    setRedoForm(open);
  };

  const saveRedoRequest = () => {
    setRedoSubmitting(true);
    const params = {
      jobApplicantVideoInterviewId: interview.jobApplicantVideoInterviewId,
      videoQuestionId: videoQueId,
      redoMessage: formValues.redoMessage ?? "",
    };

    setSubmitting(true);
    Api("POST", `Job/RedoRequestForQuestion`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          setRedoForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setRedoSubmitting(false)); // Reset redo form submitting state
  };

  const handleEditClick = (review, videoQuestionId) => {
    // Open the form for editing with the review details
    setRatingForm(true);
    setIsEditing(true); // Set isEditing to true
    setFormValues({
      rate: review.rating,
      comment: review.ratingNote,
    });
    setVideoQueId(videoQuestionId); // Update this line
    setEditingReview(review); // Store the editing review details
  };



  const handleRedoChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRatingChange = (e) => {
    setLRating(+e.target.value);
    setFormValues({ ...formValues, rate: +e.target.value });
  };

  const addOrUpdateReview = () => {
    // Placeholder API call
    setRatingSubmitting(true);
    const apiEndpoint = isEditing
      ? `Job/EditRateingQuestionWise`
      : `Job/AddRateingQuestionWise`;

    const params = {
      rateingId: isEditing ? editingReview.rateingId : "", // Include rateingId for editing
      companyId: interview.companyId,
      jobId: interview.jobId,
      applicantId: interview.applicantId,
      jobApplicantVideoInterviewId: interview.jobApplicantVideoInterviewId,
      videoQuestionId: videoQueId,
      rate: formValues.rate,
      comment: formValues.comment,
    };

    Api("POST", apiEndpoint, params).then((res) => {
      if (res.data.isSuccess === true) {
        setRatingForm(false);
        if (reviewers?.length > 0) {
          setReviewers(interview.video.reviewers);
        }
        setIsEditing(false);
        getVIData();
      }
    }).finally(() => setRatingSubmitting(false)); // Reset rating form submitting state
  };

  return (
    <div className="user-list applicant-h">
      <div className="applicant-header">
        <div className="d-flex align-items-center">
          <BackgroundLetterAvatars
            name={interview?.applicantName}
            width={32}
            height={32}
            fontSize="16px"
          />
          <strong>{interview?.applicantName}</strong>
        </div>
        <div>
          <Tooltip title="Close Applicant">
            <IconButton size="small" onClick={closeApplicantTab}>
              <CloseIcon sx={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="column-content p-16 video-col-container" style={{ height: '90%', overflowY: 'auto' }}>
        <div className="video-col">
          {interviewData.map((video, index) => (
            <div className="video-question" id={video.videoQuestionId}>
              <div className="d-flex justify-content-between align-items-center">
                <strong>Question #{index + 1}</strong>
                <span className={QUE_STATUS[video.videoQuestionStatus]}>
                  {video.videoQuestionStatus}
                </span>
                <></>
                {(video.videoQuestionStatus === "Complete" || video.videoQuestionStatus === "Completed") &&
                  interview.allowRadorequest && (
                    <Tooltip title="Redo Request">
                      <IconButton onClick={() => redoRequest(video.videoQuestionId)}>
                        <RefreshRoundedIcon sx={{ color: indigo[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}

                {video.videoQuestionStatus === "Pending" && (
                  <CancelQuestionBox
                    jobApplicantVideoInterviewId={
                      interview.jobApplicantVideoInterviewId
                    }
                    videoQuestionId={video.videoQuestionId}
                  />
                )}
              </div>
              <label className="font-12">{video.videoQuestionName}</label>

              {video.videoQuestionStatus === "Complete" ||
                (video.videoQuestionStatus === "Completed" && (
                  <div>
                    <video
                      className="poster-video"
                      key={video.videoQuestionId}
                      alt={video.videoQuestionId}
                      controls
                    >
                      <source
                        src={"https://" + video?.mediaFile?.slice(6)}
                        type="video/mp4"
                      />
                    </video>
                    {video.rating > 0 && (
                      <div className="d-flex justify-content-between align-items-center font-12 mt-10">
                        <label>Ratings:</label>
                        <div className="rating-label">
                          <label className="">{video.rating}</label>
                          <StarRateIcon
                            sx={{
                              fontSize: "14px",
                              marginLeft: "4px",
                              color: amber[600],
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {!video.reviewers.some(review => review.userType === "LoginUser" && review.userId === isUserLoggedIn) && (
                      <ButtonUI
                        variant="outlined"
                        color="#3954A5"
                        width="314px"
                        p="4px"
                        mt="4px"
                        radius="4px"
                        onClick={() => rateVideo(video.videoQuestionId)}
                        leftIcon={<StarRateIcon fontSize="small" sx={{ color: indigo[500] }} />}
                      >
                        Rate & Review
                      </ButtonUI>
                    )}
                    {video.reviewers.length > 0 && (
                      <div className="font-12">
                        <Accordion
                          expanded={
                            expanded === "panel4" + video.videoQuestionId
                          }
                          onChange={handleChange(
                            "panel4" + video.videoQuestionId
                          )}
                          disableGutters={true}
                          elevation={0}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                            <Typography>All Reviews</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {video.reviewers.map((review) => (
                              (review.rating > 0 || review.ratingNote !== "") && ( // Add condition here
                                <Typography key={review.rateingId}>

                                  <div className="all-comments">
                                    {review.rating > 0 && (
                                      <span className="rating-label">
                                        {review?.rating}
                                        <StarRateIcon
                                          sx={{
                                            fontSize: "12px",
                                            marginLeft: "4px",
                                            color: amber[600],
                                          }}
                                        />
                                      </span>
                                    )}
                                    <div className="font-12">
                                      {(review.rating > 0 || review.ratingNote !== "") && ( // Add condition here as well
                                        <label>
                                          {review?.firstName + " " + review.lastName} |{" "}
                                          {moment(review.reviewDate).format("MM/DD/YYYY")}
                                        </label>
                                      )}

                                      <br />
                                      {review.ratingNote !== "" && (
                                        <span>{review.ratingNote}</span>
                                      )}
                                    </div>
                                    {review.userType === "LoginUser" && review.userId === isUserLoggedIn && (
                                      <CreateIcon onClick={() => handleEditClick(review, video.videoQuestionId)} />
                                    )}
                                  </div>
                                </Typography>
                              )
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </div>
                ))}
              {/* Rating form start */}
              {openRatingForm && videoQueId === video.videoQuestionId && (
                <div className="mt-10">
                  <strong className="font-14 indigo-text">
                    Rate this video
                  </strong>
                  <br />
                  <div className="text-center">
                    <Rating
                      name="rate"
                      size="large"
                      value={formValues.rate}
                      onChange={handleRatingChange}
                    />
                  </div>
                  <div className="inForms rate">
                    <div className="forms-input">
                      <textarea
                        className="font-12"
                        name="comment"
                        value={formValues.comment}
                        onChange={handleRedoChange}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <ButtonUI
                        width="70px"
                        p="4px"
                        radius="4px"
                        onClick={addOrUpdateReview}
                      >
                        {isRatingSubmitting ? <div className="dot-pulse"></div> : "Save"}
                      </ButtonUI>
                      <ButtonUI
                        width="70px"
                        p="4px"
                        variant="outlined"
                        radius="4px"
                        ml="10px"
                        onClick={() => setRatingForm(false)}
                      >
                        Cancel
                      </ButtonUI>
                    </div>
                  </div>
                </div>
              )}
              {/* Rating form end */}

              {/* Redo form start */}
              {openRedoForm && videoQueId === video.videoQuestionId && (
                <div className="inForms popup" id={video.videoQuestionId} ref={myRef}>
                  <strong className="font-14 indigo-text">
                    Request a redo for this question?
                  </strong>

                  <div className="forms-input">
                    <label className="font-12">Message to Applicant :</label>
                    <textarea
                      className="font-12"
                      name="redoMessage"
                      value={formValues.redoMessage}
                      onChange={handleRedoChange}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <ButtonUI
                      width="60px"
                      p="4px"
                      radius="4px"
                      onClick={saveRedoRequest}
                    >
                      {isRedoSubmitting ? <div className="dot-pulse"></div> : "Yes"}
                    </ButtonUI>
                    <ButtonUI
                      width="60px"
                      p="4px"
                      variant="outlined"
                      radius="4px"
                      ml="10px"
                      onClick={() => setRedoForm(false)}
                    >
                      No
                    </ButtonUI>
                  </div>
                </div>
              )}
              {/* Redo form end */}
              <div className="border-bottom"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
