import { IconButton, Tooltip, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Link } from "react-router-dom";
import { indigo } from "@material-ui/core/colors";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import jsPDF from "jspdf";
import moment from "moment";
import { ReportSortFilter } from "./reportSortFilter";

export const HireReport = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [totalPage, setTotalPage] = useState(1);
  const [locationOptions, setLocationOptions] = useState([]);
  const [deptOptions, setDeptOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [hiresExportData, setHiresExportData] = useState([]);
  const [dateDisplay, setDateDisplay] = useState("");
  const [fromDate, setFromDate] = useState(new Date().toISOString());
  const [toDate, setToDate] = useState(new Date().toISOString());

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getHireReport();
    getLocationOptions();
    getHiresExportData();
  }, [pageNumber]);

  const getHiresExportData = (
    dateFrom = fromDate,
    dateTo = toDate,
    dtDisplay = dateDisplay
  ) => {
    const query = `companyId=${companyData.companyId}&dateTo=${dateTo}&dateFrom=${dateFrom}&dateDispaly=${dtDisplay}&pageLimit=10&pageNumber=${pageNumber}&orderBy=desc&orderByDirection=createdAt`;

    Api("GET", `Report/HiresReportExport?${query}`).then((res) => {
      setHiresExportData(res.data.data);
    });
  };

  const getLocationOptions = () => {
    Api("GET", `Facilities/GetAllFacilities?companyId=${companyData.companyId}`)
      .then((res) => {
        setLocationOptions(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getHireReport = (
    dateFrom = fromDate,
    dateTo = toDate,
    dtDisplay = dateDisplay
  ) => {
    setIsLoader(true);

    const query = `companyId=${companyData.companyId}&dateTo=${dateTo}&dateFrom=${dateFrom}&dateDispaly=${dtDisplay}&pageLimit=10&pageNumber=${pageNumber}&orderBy=desc&orderByDirection=createdAt`;
    Api("GET", `Report/HiresReport?${query}`)
      .then((res) => {
        setRows(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (fileName) => {
    Api(
      "GET",
      `Report/HiresReportExport?companyId=${companyData.companyId}&dateDispaly=${dateDisplay}&dateFrom=${fromDate}&dateTo=${toDate}`
    )
      .then((res) => {
        if (res.data.data == null || res.data.data.length == 0) {
          return;
        }

        const ws = XLSX.utils.json_to_sheet(res.data.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
      });
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const Row = rows?.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val["Name"]}</td>
        <td>{val["Job Name & No."]}</td>
        <td>{val["Company"]}</td>
        <td>{val["Hired Date"]}</td>
        <td>{val["Termination Date"]}</td>
      </tr>
    );
  });

  const exportPDF = () => {
    getHiresExportData();
    if (hiresExportData == null || hiresExportData.length == 0) {
      return;
    }
    var doc = new jsPDF("l", "mm", "a4");

    doc.setFontSize(10);

    doc.text(20, 20, moment().format("MM/DD/YY, HH:mm a"));
    doc.text(260, 20, "TalentATS");

    doc.setFontSize(22);
    doc.text(20, 30, "Hires Report");

    const columns = [
      { title: "Name", dataKey: "Name" },
      { title: "Job Name & No.", dataKey: "Job Name & No." },
      { title: "Company", dataKey: "Company" },
      { title: "Hired Date", dataKey: "Hired Date" },
      { title: "Termination Date", dataKey: "Termination Date" },
    ];

    var options = {
      margin: {
        top: 20,
      },
      startY: 40,
    };

    doc.setFontSize(10);
    doc.autoTable(columns, hiresExportData, options);

    doc.save("Hire_Report.pdf");
  };

  const handleSortFilter = (flag, elemId, dateTo, dateFrom, dtDisplay) => {
    setPage(1);
    if (
      dateTo === undefined ||
      dateFrom === undefined ||
      dtDisplay === undefined
    ) {
      setFromDate(new Date().toISOString());
      setToDate(new Date().toISOString());
      setDateDisplay("");
      getHireReport();
      getHiresExportData();
    } else {
      setFromDate(dateFrom);
      setToDate(dateTo);
      setDateDisplay(dtDisplay);
      getHireReport(dateFrom, dateTo, dtDisplay);
      getHiresExportData(dateFrom, dateTo, dtDisplay);
    }
  };

  const handleDateFilters = () => {};

  return (
    <div>
      <div className="company_content">
        <div className="d-flex flex-column border-bottom">
          <div className="d-flex report-header">
            <div className="company_text">Hires Report</div>
            <div className="d-flex">
              <ReportSortFilter
                locationOptions={locationOptions}
                deptOptions={deptOptions}
                companyOptions={companyOptions}
                isDate={true}
                handleSortFilter={handleSortFilter}
                handleDateFilters={handleDateFilters}
                companyId={companyData.companyId}
                setpage={setPage}
              />
              <Tooltip title="Download">
                <IconButton onClick={() => exportToCSV("Hire_Report")}>
                  <FileDownloadOutlinedIcon sx={{ color: indigo[500] }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Print PDF">
                <IconButton onClick={() => exportPDF()}>
                  <LocalPrintshopOutlinedIcon sx={{ color: indigo[500] }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="top-url">
            <p>
              <Link to="/reports">Reports &gt; </Link>
              <Link to="/reports/hire">
                <strong>Hires Report</strong>
              </Link>
            </p>
          </div>
        </div>

        {isLoader ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table" style={{ marginTop: 0 }}>
              <table>
                <thead>
                  <tr className="report-titles">
                    <td className="th_name">
                      Name
                      <button className="icon icon_button">
                        {/* <img
                          src={
                            require("../../../assets/images/tabsort.svg")
                              .default
                          }
                          alt="sort-icon"
                          className="icon sort_icon"
                        /> */}
                      </button>
                    </td>
                    <td>Job Name & No.</td>
                    <td>Company</td>
                    <td>Hired Date</td>
                    <td>Termination Date</td>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
