import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton, MenuItem, Tooltip } from "@mui/material";
import { indigo } from "@mui/material/colors";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ButtonUI } from "../../../components/ui/button";
import { initialJobsFilters } from "../../../helper/constants";
import ReactDatePicker from "react-datepicker";

const TYPE = {
  SORT: "SORT",
  FILTER: "FILTER",
};

const FILTERIDS = {
  DATE: "date",
  COMPANY: "companyId",
  LOCATION: "location",
  DEPT: "deptId",
  DATE_RANGE: "dateRange",
};

const FILTER_BY_OPTIONS = [
  // {
  //   id: "DATE",
  //   name: "Date",
  // },
  {
    id: "COMPANY",
    name: "Company",
  },
  {
    id: "LOCATION",
    name: "Location",
  },
  {
    id: "DEPT",
    name: "Department",
  },
  // {
  //   id: "DATE_RANGE",
  //   name: "Date Range",
  // },
];

const dateOptions = [
  {
    key: "recent",
    label: "Recent",
    dateFrom: new Date().toISOString(),
    dateTo: new Date().toISOString(),
  },
  {
    key: "today",
    label: "Today",
    dateFrom: new Date().toISOString(),
    dateTo: new Date().toISOString(),
  },
  {
    key: "yesterday",
    label: "Yesterday",
    dateFrom: new Date(Date.now() - 864e5).toISOString(),
    dateTo: new Date().toISOString(),
  },
  {
    key: "this_week",
    label: "This Week",
    dateFrom: new Date(
      new Date().getTime() - 7 * 24 * 60 * 60 * 1000
    ).toISOString(),
    dateTo: new Date().toISOString(),
  },
  {
    key: "last_week",
    label: "Last Week",
    dateFrom: new Date(
      new Date().getTime() - 14 * 24 * 60 * 60 * 1000
    ).toISOString(),
    dateTo: new Date(
      new Date().getTime() - 7 * 24 * 60 * 60 * 1000
    ).toISOString(),
  },
  {
    key: "this_month",
    label: "This Month",
    dateFrom: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toISOString(),
    dateTo: new Date().toISOString(),
  },
  {
    key: "last_month",
    label: "Last Month",
    dateFrom: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    ).toISOString(),
    dateTo: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      0
    ).toISOString(),
  },
  {
    key: "this_year",
    label: "This Year",
    dateFrom: new Date(new Date().getFullYear() - 1, 0, 1).toISOString(),
    dateTo: new Date().toISOString(),
  },
  {
    key: "last_year",
    label: "Last Year",
    dateFrom: new Date(new Date().getFullYear() - 1, 0, 1).toISOString(),
    dateTo: new Date(new Date().getFullYear(), 0, 0).toISOString(),
  },
  // {
  //   key: "date_range",
  //   label: "Date Range",
  //   dateRange: true,
  // },
];

const theme = createTheme({});

export const ReportSortFilter = ({
  companyOptions,
  locationOptions,
  deptOptions,
  handleSortFilter,
  appliedFilters,
  isDate = false,
  companyId,
  setPage,
  isAppSrcReport = 0,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(initialJobsFilters);
  const [flag, setFlag] = useState(""); // 1 for Date, 2 for Company, 3 for Facilities, 4 for Department
  const [elemId, setElId] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [currentFilterOption, setCurrentFilterOption] = useState(
    isDate === false ? FILTER_BY_OPTIONS[0] : { id: "DATE", name: "Date" }
  );

  useEffect(() => {
    setFilter(appliedFilters);
  }, [open]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setFlag(1);
    setStartDate(start);
    setDateFrom(start.toISOString());
    end !== null && setDateTo(end.toISOString());
    setEndDate(end);
  };

  const handleFilters = (elId, elFlag, dateFrom, dateTo) => {
    setFlag(elFlag);
    setElId(elId);
    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const handleApplyFilters = () => {
    let endDate =
      dateTo == "" || dateTo == null || dateTo == undefined ? dateFrom : dateTo;
    const rFlag =
      currentFilterOption.id === "DATE_RANGE" ? "Date Range" : elemId;
    handleSortFilter(flag, elemId, endDate, dateFrom, rFlag);
    handleClose();
    setPage(1);
  };

  const handleResetFilters = () => {
    setFlag(0);
    setElId("");
    handleSortFilter(
      0,
      "",
      new Date().toISOString(),
      new Date().toISOString(),
      ""
    );
    handleClose();
    setStartDate();
    setEndDate();
    setPage(1);
  };

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };

  const handleOpenFilter = (el) => {
    setCurrentFilterOption(el);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Tooltip placement="top" title="Sort & Filter">
          <div className="position-relative">
            <IconButton
              size="small"
              id="menubutton22"
              aria-owns={open ? "simple-menu2" : null}
              aria-haspopup="true"
              onClick={handleOpen}
              className="filter-sort-icon"
            >
              <FilterListIcon sx={{ color: indigo[500] }} />
            </IconButton>
            {appliedFilters !== initialJobsFilters && (
              <div className="filter-dot" />
            )}
          </div>
        </Tooltip>
        <Menu
          id="simple-menu2"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={handleClose}
        >
          <div className="filter-menu-container">
            <div className="filter-main-menu">
              <div className="main-filter-heading">Filter By</div>
              <div style={{ paddingLeft: 4 }}>
                {isAppSrcReport === 0 &&
                  isDate === false &&
                  FILTER_BY_OPTIONS.map((el) => (
                    <MenuItem
                      sx={{
                        backgroundColor:
                          el.id === currentFilterOption.id &&
                          "rgba(0, 0, 0, 0.1)",
                      }}
                      onClick={() => handleOpenFilter(el)}
                    >
                      <div
                        className={
                          el.id === currentFilterOption.id
                            ? "dot-div selected"
                            : "dot-div filter"
                        }
                      ></div>
                      {el.name}
                    </MenuItem>
                  ))}
                {/* For Applicant source Report start */}
                {isAppSrcReport === 1 && (
                  <MenuItem
                    sx={{
                      backgroundColor:
                        "COMPANY" === currentFilterOption.id &&
                        "rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={() =>
                      setCurrentFilterOption({ id: "COMPANY", key: "Company" })
                    }
                  >
                    <div
                      className={
                        "COMPANY" === currentFilterOption.id
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    ></div>
                    Company
                  </MenuItem>
                )}
                {/* For Applicant source Report end */}
                <MenuItem
                  sx={{
                    backgroundColor:
                      "DATE" === currentFilterOption.id && "rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() =>
                    setCurrentFilterOption({ id: "DATE", key: "Date" })
                  }
                >
                  <div
                    className={
                      "DATE" === currentFilterOption.id
                        ? "dot-div selected"
                        : "dot-div filter"
                    }
                  ></div>
                  Date
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor:
                      "DATE_RANGE" === currentFilterOption.id &&
                      "rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() =>
                    setCurrentFilterOption({
                      id: "DATE_RANGE",
                      key: "Date Range",
                    })
                  }
                >
                  <div
                    className={
                      "DATE_RANGE" === currentFilterOption.id
                        ? "dot-div selected"
                        : "dot-div filter"
                    }
                  ></div>
                  Date Range
                </MenuItem>
              </div>
            </div>
            <div className="sub-main-filter-container">
              {/* {currentFilterOption.id !== "DATE_RANGE" && (
                <MenuItem
                  onClick={() =>
                    handleFilters(null, FILTERIDS[currentFilterOption.id])
                  }
                >
                  All
                </MenuItem>
              )} */}
              {currentFilterOption.id === "LOCATION" &&
                locationOptions.map((facility) => (
                  <>
                    {facility.address !== "" && (
                      <MenuItem
                        key={facility.facilitiesId}
                        onClick={() => handleFilters(facility.facilitiesId, 3)}
                      >
                        <div
                          className={
                            facility.facilitiesId === elemId
                              ? "dot-div selected"
                              : "dot-div filter"
                          }
                        ></div>
                        {facility.address}
                      </MenuItem>
                    )}
                  </>
                ))}
              {currentFilterOption.id === "COMPANY" && (
                <>
                  {companyOptions?.map((comp) => (
                    <MenuItem
                      key={comp.companyId}
                      onClick={() => handleFilters(comp.companyId, 2)}
                    >
                      <div
                        className={
                          comp.companyId === elemId
                            ? "dot-div selected"
                            : "dot-div filter"
                        }
                      ></div>
                      {comp.name}
                    </MenuItem>
                  ))}
                  <MenuItem
                    key={companyId}
                    onClick={() => handleFilters(companyId, 2)}
                  >
                    <div
                      className={
                        companyId === elemId
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    ></div>
                    {localStorage.getItem("companyName")}
                  </MenuItem>
                </>
              )}

              {/* {currentFilterOption.id === "COMPANY" &&
                companyOptions.length === 0 && (
                  <div className="inner-h job-sidebar no-data">
                    <img src={require("../../../assets/images/no-data.png")} />
                    <span>No data found!</span>
                  </div>
                )} */}

              {currentFilterOption.id === "DATE" &&
                dateOptions.map((p) => (
                  <MenuItem
                    key={p.key}
                    onClick={() =>
                      handleFilters(p.label, 1, p.dateFrom, p.dateTo)
                    }
                  >
                    <div
                      className={
                        p.label === elemId
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    ></div>
                    {p.label}
                  </MenuItem>
                ))}
              {currentFilterOption.id === "DATE_RANGE" && (
                <ReactDatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
              )}
              {currentFilterOption.id === "DEPT" &&
                deptOptions.map((p) => (
                  <MenuItem
                    key={p.departmentId}
                    onClick={() => handleFilters(p.departmentId, 4)}
                  >
                    <div
                      className={
                        p.departmentId === elemId
                          ? "dot-div selected"
                          : "dot-div filter"
                      }
                    ></div>
                    {p.name}
                  </MenuItem>
                ))}
            </div>
          </div>
          <div className="filter-btn-main-container">
            <div className="filter-btns">
              <ButtonUI
                p={8}
                width={"60%"}
                color={"#3954A5"}
                variant="outlined"
                onClick={handleResetFilters}
                disabled={appliedFilters === initialJobsFilters}
              >
                RESET
              </ButtonUI>
            </div>
            <div className="filter-btns">
              <ButtonUI
                p={8}
                width={"60%"}
                variant={
                  filter === initialJobsFilters ? "outlined" : "contained"
                }
                onClick={handleApplyFilters}
                disabled={filter === initialJobsFilters}
              >
                APPLY
              </ButtonUI>
            </div>
          </div>
        </Menu>
      </ThemeProvider>
    </div>
  );
};
