import React, { useEffect } from "react";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import {
  DeleteIcon,
  DownloadIcon,
  PDFIcon,
  ReplaceIcon,
} from "../../../../../../../icons";
import fileDownload from "js-file-download";
import axios from "axios";
import Api from "../../../../../../../helper/Api";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { CVDocumentIcon } from "../../../../../../../icons/document/cv";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../../../../redux/applicantData/actions";
import { ButtonUI } from "../../../../../../ui/button";
import { Tooltip } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FilesIcon, CloseXIcon } from "../../../../../../../icons";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";
import { useSelector } from "react-redux";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { blue } from "@material-ui/core/colors";
import { AlertBox, showSuccess } from "../../../../../../../helper/utils";

export const ResumeTab = ({
  applicantData,
  selectedSuitCase,
  cvUpdated,
  setCvUpdated,
}) => {
  const [resume, setResume] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const attachRef = useRef(null);
  const rAttachRef = useRef(null);
  const formData = new FormData();
  const dispatch = useDispatch();
  const [attachment, setAttachment] = useState("");
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );
  const [resumeData, setResumeData] = useState([]);

  useEffect(() => {
    getResumeData();
  }, [applicantData]);

  // useEffect(() => {
  //   if (cvUpdated === true) {
  //     getApplicantById(true);
  //   }
  // }, [cvUpdated]);

  const getResumeData = () => {
    Api(
      "GET",
      `Job/GetResumeData?companyId=${applicantData.companyId}&jobId=${applicantData.jobId}&applicantId=${applicantData.applicantId}`
    )
      .then((res) => {
        setResumeData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApplicantById = (isUpdated = false) => {
    Api(
      "GET",
      `Job/GetApplicantById?companyId=${applicantData.companyId}&jobId=${applicantData.jobId}&applicantId=${applicantData.applicantId}`
    )
      .then((res) => {
        const data = [res.data, ...applicantsArray];
        data.map((rec) => {
          if (rec.applicantId === applicantData.applicantId) {
            rec.resume = res.data.viewResume;
          }
        });
        if (isUpdated === true) {
          setCvUpdated(false);
        }
        // dispatch({
        //   type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
        //   payload: data,
        // });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadResume = (name) => {
    axios
      .get(resumeData.viewResume, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, name);
      });
  };

  const deleteResume = () => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      setLoading(true);
      setAttachment("");
      Api(
        "DELETE",
        `Job/DeleteResume?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            showSuccess();
            let applicanInfo = { ...applicantData };
            applicanInfo.viewResume = "";
            getResumeData();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  // const handleResume = (e) => {
  //   setResume(e.target.files[0]);
  //   setFormErrors({});
  //   setAttachment(e.target.files[0].name);
  // };

  const uploadResume = (e) => {
    setLoading(true);
    if (!resume) {
      setFormErrors({ resume: "Please select a file to upload!" });
      return;
    }

    formData.append("resume", e.target.files[0]);
    formData.append("companyId", applicantData?.companyId);
    formData.append("applicantId", applicantData?.applicantId);
    formData.append("jobId", selectedSuitCase?.jobId);

    replaceResumeAPI(formData, e.target.files[0]);
  };

  const replaceResume = (e) => {
    setLoading(true);
    formData.append("resume", e.target.files[0]);
    formData.append("companyId", applicantData?.companyId);
    formData.append("applicantId", applicantData?.applicantId);
    formData.append("jobId", selectedSuitCase?.jobId);

    replaceResumeAPI(formData, e.target.files[0]);
  };

  const replaceResumeAPI = (data, file) => {
    Api("POST", "Job/ReplaceResume", data)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          setAttachment(file.name);
          getResumeData();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = () => {
    attachRef.current.click();
  };

  const openResume = () => {
    window.open(resumeData.viewResume, "newwindow", "width=1500,height=950");
  };

  return (
    <>
      <input
        type="file"
        ref={attachRef}
        hidden
        onChange={uploadResume}
        key={attachment}
        accept="image/png, image/jpg, image/jpg, .doc, .docx, .pdf"
      />

      {loading ? (
        <ColoredLinearProgress />
      ) : resumeData?.fileDisplayName ? (
        <div className="tab-container">
          <input
            type="file"
            multiple
            ref={rAttachRef}
            hidden
            onChange={replaceResume}
            accept=".pdf, .doc, .docx"
          />
          <div className="d-flex justify-content-between align-items-center p-0-16 mt-15">
            <span className="heading-label font-blue">Resume:</span>
            <div className="options-container">
              <div className="cv-icon-info icon-border-right">
                <Tooltip title="View Resume">
                  <div role="button" className="icon-container">
                    <VisibilityOutlinedIcon
                      onClick={openResume}
                      sx={{ color: blue[600] }}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="cv-icon-info icon-border-right">
                <Tooltip title="Download">
                  <div role="button" className="icon-container">
                    <DownloadIcon
                      height="20px"
                      width="20px"
                      onClick={() =>
                        downloadResume(resumeData?.fileDisplayName)
                      }
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="cv-icon-info icon-border-right">
                <Tooltip title="Replace">
                  <div
                    role="button"
                    className="icon-container"
                    onClick={handleClick}
                  >
                    <ReplaceIcon height="20px" width="20px" type="file" />
                  </div>
                </Tooltip>
              </div>
              <div className="cv-icon-info">
                <Tooltip title="Delete">
                  <div role="button" className="icon-container">
                    <DeleteIcon
                      height="20px"
                      width="20px"
                      onClick={deleteResume}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className={
              resumeData?.fileDisplayName
                ? "resume-content-container p-0-16 mt-5"
                : "resume-content-container file-upload p-0-16 mt-5"
            }
          >
            <div className="resume-details">
              <div
                className={
                  resumeData?.fileDisplayName?.split(".")?.pop() === "pdf"
                    ? "cv-icon-container pdf"
                    : resumeData?.fileDisplayName?.split(".")?.pop() === "doc"
                    ? "cv-icon-container doc"
                    : "cv-icon-container img"
                }
              >
                {resumeData?.fileDisplayName?.split(".")?.pop() === "pdf" ? (
                  <PDFIcon height="32px" width="32px" />
                ) : resumeData?.fileDisplayName?.split(".")?.pop() === "doc" ? (
                  <CVDocumentIcon height="28px" width="28px" />
                ) : (
                  <img
                    src={require("../../../../../../../assets/images/img.png")}
                    width="34"
                  />
                )}
              </div>
              <div className="details-container">
                <div className="resume-name">{resumeData?.fileDisplayName}</div>
                <div className="resume-status">
                  Uploaded on{" "}
                  {resumeData.resumeUploadedDate
                    ? moment(resumeData.resumeUploadedDate).format("MM/DD/YYYY")
                    : moment().format("MM/DD/YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-container">
          {attachment ? (
            <div className="p-0-16 mt-15">
              <div className="doucments-card applicant">
                <div className="doc-detail">
                  <FilesIcon
                    stroke="#9EA0A5"
                    line="#fff"
                    width="30px"
                    height="30px"
                  />
                  <div className="file-name-label">{attachment}</div>
                </div>
                <div
                  className="doc-detail"
                  role="button"
                  onClick={() => setAttachment("")}
                >
                  <div className="pointer">
                    <CloseXIcon width="20px" height="20px" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-0-16">
              <strong className="font-14 font-blue">Resume:</strong>
              <div
                role="button"
                className="mt-10 document applicant pointer"
                onClick={() => handleClick()}
              >
                <div className="documents-upload">
                  <span>Upload Document</span>
                  <CloudUploadOutlinedIcon width="32" height="32" />
                </div>
              </div>
              <span className="success_msg">
                * Allowed File types are .doc, .docx, .pdf, .jpg, .png
              </span>
              <span className="error_msg">{formErrors?.resume}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};
