import { useState } from "react";
import { ButtonUI } from "../../../../../../ui/button";
import { AcceptPreHireForm } from "./acceptPreHireForm";
import { CancelPreHireForm } from "./cancelPreHireForm";
import { CreateAptForm } from "./createAptForm";
import { CreatePreHirePack } from "./createPreHirePack";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { CancelDocPackForm } from "./cancelDocPackForm";
import { SendPackForm } from "./sendPackForm";
import { AddDocumentForm } from "./addDocumentForm";
import fileDownload from "js-file-download";
import axios from "axios";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import { DownloadButton } from "../../../../../../ui/button/download";
import { DeleteButton } from "../../../../../../ui/button/delete";
import { AcceptButton } from "../../../../../../ui/button/accept";
import { AlertBox, showSuccess } from "../../../../../../../helper/utils";
import { EclipseLoader } from "../../../../../eclipseLoader";
import { baseUrl } from "../../../../../../../helper/constants";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

const statusColor = {
  SIGNED_PREHIRE: "green-active",
  ACCEPTED_PREHIRE: "green-active",
  DELIVERED_PREHIRE: "",
  DECLINED_PREHIRE: "blue-completed",
  REJECTED_PREHIRE: "red-delete",
  CREATED_PREHIRE: "light-blue",
  Declined: "red-delete",
  Requested: "blue-completed",
  Accepted: "green-active",
  Cancelled: "",
};

export const PreHire = ({ selectedSuitCase, applicantId }) => {
  const [openAptForm, setOpenAptForm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [openCancelForm, setCancelForm] = useState(false);
  const [openAcceptForm, setAcceptFrom] = useState(false);
  const [openPreHirePack, setOpenPreHirePack] = useState(false);
  const [physicalPpdList, setPhysicalPpdList] = useState([]);
  const [docPackList, setDocPackList] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [openPackCancelForm, setPackCancelForm] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [id, setId] = useState("");
  const [openSendDocPackForm, setSendDocPackForm] = useState(false);
  const [openAddDocForm, setAddDocForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const formData = new FormData();

  useEffect(async () => {
    var the_arr = baseUrl.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    connect.on("PhysicalPPDList", (res) => {
      setPhysicalPpdList(res);
    });

    connect.on("PreHirePackList", (res) => {
      setDocPackList(res);
    });

    await connect.start();

  }, [selectedSuitCase.jobId]);

  useEffect(() => {
    setLoader(true);
    getPreHireList();
    getPreHireDocPackList();
  }, []);

  const toggleAptForm = (open) => {
    setOpenAptForm(open);
  };

  const toggleCancelForm = (open) => {
    setCancelForm(open);
  };

  const toggleAcceptForm = (open) => {
    setAcceptFrom(open);
  };

  const togglePreHirePack = (open) => {
    setOpenPreHirePack(open);
  };

  const togglePackCancelForm = (open) => {
    setPackCancelForm(open);
  };

  const toggleAddDocForm = (open) => {
    setAddDocForm(open);
  };

  // Request Physical / PPD list
  function getPreHireList() {
    Api(
      "GET",
      `Job/GetPhysicalPPDRequestList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    ).then((res) => {
      setPhysicalPpdList(res.data);
    });
  }

  // Pre Hire Document pack list
  function getPreHireDocPackList() {
    Api(
      "GET",
      `Job/GetPreHireDocumentPackList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setDocPackList(res.data);
      })
      .finally(() => setLoader(false));
  }

  // Cancel PreHire Physical / PPD
  const cancelPhysicalPpd = (comment) => {
    setSubmitting(true);
    const params = {
      preHireRequestId: id,
      preHireRequestComment: comment,
    };

    Api("POST", `Job/CancelPhysicalPPDRequest`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleCancelForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Create PreHire Physical / PPD form
  const savePreHireForm = () => {
    const params = { ...formValues };
    params.companyId = selectedSuitCase.companyId;
    params.jobId = selectedSuitCase.jobId;
    params.applicantId = applicantId;
    params.duration = +formValues.duration;
    params.participants = formValues?.participants ?? [];
    params.participantList = !formValues?.participantList
      ? []
      : formValues?.participantList;

    setSubmitting(true);
    Api("POST", `Job/SavePhysicalPPDRequest`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAptForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Accept PreHire Physical / PPD request
  const acceptPhysicalPPD = (slots) => {
    setSubmitting(true);
    const params = {
      preHireRequestId: id,
      acceptedSlot: slots,
    };

    Api("POST", `Job/AcceptPhysicalPPDRequest`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAcceptForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // PreHire Pack accept icon
  const handleAcceptDoc = () => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;
      handleDocPackIcons("Accepted", "Document pack accepted successfully!", 1);
    });
  };

  // PreHire Pack cancel icon
  const cancleDocPack = (comment) => {
    handleDocPackIcons(
      "Rejected",
      "Document pack rejected successfully!",
      2,
      comment
    );
  };

  // PreHire Pack delete icon
  const handleDeleteDoc = (id, dId) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;
      handleDocPackIcons(
        "Deleted!",
        "Document pack deleted successfully!",
        3,
        "",
        id,
        dId
      );
    });
  };

  /**
   * For submitting the icons API call
   * @param {String} title
   * @param {String} message
   * @param {*} flag
   *
   * 1 for Accept, 2 for reject / cancel and 3 for delete
   *
   */
  const handleDocPackIcons = (
    title,
    message,
    flag,
    comment = "",
    pid = id,
    dId = documentId
  ) => {
    const params = {
      preHireDocumentPackId: pid,
      documentId: dId,
      comments: comment,
      flag: flag,
    };

    setSubmitting(true);
    Api("POST", `Job/PreHireDocumentPackIcons`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          showSuccess(title, res.data.message);
          togglePackCancelForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Create PreHire Document Pack
  const saveDocPack = (docPackList) => {
    setSubmitting(true);
    const params = {
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
      documentPackId: "",
      documentPackList: docPackList,
    };

    Api("POST", `Job/PreHireDocumentPackSave`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          togglePreHirePack(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const toggleSendPackForm = (open) => {
    setSendDocPackForm(open);
  };

  const handleSendPack = (comment) => {
    setSubmitting(true);
    const params = {
      preHireDocumentPackId: docPackList[0].preHireDocumentPackId,
      messageToApplicant: comment,
    };

    Api("POST", `Job/SendDocumentPackPreHire`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleSendPackForm(false);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const saveDocument = () => {
    setSubmitting(true);
    formData.append(
      "preHireDocumentPackId",
      docPackList[0].preHireDocumentPackId
    );
    formData.append("SelectedDocLists", formValues?.SelectedDocLists ?? "");
    formData.append("UploadDocLists", formValues?.UploadDocLists ?? {});

    Api("POST", `Job/SaveExistingPreHireDocument`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAddDocForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Download the document
  const downloadDocument = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, url.split("/").at(-1));
      });
  };

  return (
    <div>
      {openAptForm && (
        <CreateAptForm
          toggleForm={toggleAptForm}
          openForm={openAptForm}
          formValues={formValues}
          setFormValues={setFormValues}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          applicantId={applicantId}
          jobId={selectedSuitCase.jobId}
          savePreHireForm={savePreHireForm}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isSubmitting={isSubmitting}
        />
      )}

      {openCancelForm && (
        <CancelPreHireForm
          toggleForm={toggleCancelForm}
          openForm={openCancelForm}
          applicantId={applicantId}
          cancelPhysicalPpd={cancelPhysicalPpd}
          isSubmitting={isSubmitting}
        />
      )}

      {openAcceptForm && (
        <AcceptPreHireForm
          toggleForm={toggleAcceptForm}
          openForm={openAcceptForm}
          applicantId={applicantId}
          selectedSlots={selectedSlots}
          acceptPhysicalPPD={acceptPhysicalPPD}
          isSubmitting={isSubmitting}
        />
      )}

      {openPreHirePack && (
        <CreatePreHirePack
          toggleForm={togglePreHirePack}
          openForm={openPreHirePack}
          applicantId={applicantId}
          saveDocPack={saveDocPack}
          companyId={selectedSuitCase.companyId}
          isSubmitting={isSubmitting}
        />
      )}

      {openPackCancelForm && (
        <CancelDocPackForm
          toggleForm={togglePackCancelForm}
          openForm={openPackCancelForm}
          applicantId={applicantId}
          cancleDocPack={cancleDocPack}
          isSubmitting={isSubmitting}
        />
      )}

      {openSendDocPackForm && (
        <SendPackForm
          openForm={openSendDocPackForm}
          toggleForm={toggleSendPackForm}
          applicantId={applicantId}
          handleSendPack={handleSendPack}
          isSubmitting={isSubmitting}
        />
      )}

      {openAddDocForm && (
        <AddDocumentForm
          openForm={openAddDocForm}
          toggleForm={toggleAddDocForm}
          applicantId={applicantId}
          saveDocument={saveDocument}
          formValues={formValues}
          setFormValues={setFormValues}
          companyId={selectedSuitCase.companyId}
          isSubmitting={isSubmitting}
        />
      )}

      <div>
        <ButtonUI
          p="9px"
          ml="70px"
          onClick={() => (
            toggleAptForm(true),
            setFormValues({}),
            setFormErrors({}),
            setActiveStep(0)
          )}
        >
          Request Physical / PPD
        </ButtonUI>
        <div className="profile-data mt-16">
          {physicalPpdList.map((rec, bindex) => (
            <div className="section border-bottom" key={bindex}>
              <div>
                <strong>{rec.appoinmentTypeName}</strong>
                <span
                  className={
                    "status " + statusColor[rec.preHireRequestStatus] + " ml-20"
                  }
                >
                  {rec.preHireRequestStatus}
                </span>
              </div>
              <span className="scheduled">
                <label>
                  <strong>Participant :</strong>
                  {rec.participantsLists.map((participant, index) => (
                    <label>
                      {participant.participantFullName}
                      {index + 1 === rec.participantsLists.length ? " " : ", "}
                    </label>
                  ))}
                </label>
              </span>
              <span className="d-flex mt-5">
                {rec.preHireRequestStatus !== "Declined" &&
                  rec.preHireRequestStatus !== "Cancelled" &&
                  rec.preHireRequestStatus !== "Accepted" && (
                    <AcceptButton
                      onClick={() => (
                        toggleAcceptForm(true),
                        setSelectedSlots(rec.suggestedSlots),
                        setId(rec.preHireRequestId)
                      )}
                    />
                  )}

                {rec.preHireRequestStatus !== "Cancelled" && (
                  <DeleteButton
                    title="Cancel"
                    onClick={() => (
                      toggleCancelForm(true), setId(rec.preHireRequestId)
                    )}
                  />
                )}
              </span>
            </div>
          ))}
        </div>
        {isLoader === true ? (
          <EclipseLoader />
        ) : docPackList?.length === 0 ? (
          <ButtonUI
            p="9px"
            mt="10px"
            ml="75px"
            mb="10px"
            onClick={() => togglePreHirePack(true)}
          >
            Create PreHire Pack
          </ButtonUI>
        ) : (
          <>
            {docPackList[0]?.documentPackLists.length > 0 && (
              <ButtonUI
                p="9px"
                mt="10px"
                ml="70px"
                onClick={() => toggleSendPackForm(true)}
              >
                Send Document Pack
              </ButtonUI>
            )}
          </>
        )}
        <div className="profile-data mt-10">
          {docPackList.map((docs) => (
            <>
              {docs?.documentPackLists.map((doc, index) => (
                <div className="section border-bottom" key={index}>
                  <div>
                    <strong>{doc.displayFileName}</strong>
                    <span
                      className={"status " + statusColor[doc?.documentStatus]}
                    >
                      {doc?.documentStatus?.split("_")[0]}
                    </span>
                  </div>

                  <span className="scheduled">
                    <label>
                      <strong>Last Viewed :</strong>
                      {doc.lastViewed}
                    </label>
                  </span>
                  <span className="d-flex">
                    {/* Accept option */}
                    {doc.documentStatus === "SIGNED_PREHIRE" ||
                      doc.documentStatus === "DECLINED_PREHIRE" ||
                      (doc.documentStatus === "REJECTED_PREHIRE" && (
                        <AcceptButton
                          onClick={() => (
                            handleAcceptDoc(),
                            setId(docs.preHireDocumentPackId),
                            setDocumentId(doc.documentId)
                          )}
                        />
                      ))}

                    {/* Cancel option */}
                    {doc.documentStatus === "SIGNED_PREHIRE" ||
                      (doc.documentStatus === "ACCEPTED_PREHIRE" && (
                        <DeleteButton
                          title="Cancel"
                          onClick={() => (
                            togglePackCancelForm(true),
                            setId(docs.preHireDocumentPackId),
                            setDocumentId(doc.documentId)
                          )}
                        />
                      ))}

                    {/* Delete option */}
                    {doc.documentStatus !== "DELIVERED_PREHIRE" && (
                      <DeleteButton
                        onClick={() => (
                          handleDeleteDoc(
                            docs.preHireDocumentPackId,
                            doc.documentId
                          ),
                          setId(docs.preHireDocumentPackId),
                          setDocumentId(doc.documentId)
                        )}
                      />
                    )}

                    {/* Download option */}
                    {(doc.documentStatus === "SIGNED_PREHIRE" ||
                      doc.documentStatus === "ACCEPTED_PREHIRE") && (
                        <DownloadButton
                          onClick={() => downloadDocument(doc.documentUrl)}
                        />
                      )}
                  </span>
                </div>
              ))}
            </>
          ))}
          {docPackList.length > 0 && (
            <div className="text-center pointer">
              <ButtonUI
                p="7px"
                mt="10px"
                radius="6px"
                color="#1F68D1"
                borderColor="#1F68D1"
                variant="outlined"
                ml="80px"
                onClick={() => toggleAddDocForm(true)}
                rightIcon={<AddCircleOutlineOutlined fontSize="small" />}
              >
                Add Documents
              </ButtonUI>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
