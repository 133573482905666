import { LinkIcon } from "../../../icons";
import { Fragment, useMemo, useState } from "react";
import { URLDialogbox } from "../../common/dialogBox/urlDialogbox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton, Tooltip } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getDays } from "../../../helper/utils";

const color = {
  ON_HOLD: "red",
  ACTIVE: "active",
  CANCELLED: "",
  FILLED: "brown",
  HIRING: "lblue",
  PENDING: "yellow",
};

const progressStatus = {
  NOT_STARTED: "light-p",
  IN_PROGRESS: "light-y",
  COMPLETE: "light-g",
};

const priority = {
  NORMAL: "",
  MEDIUM: "yellow",
  HIGH: "red",
};

export const JCCard = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Dione");
  const urlJobData = useMemo(
    () =>
      window.btoa("jobId=" + props?.jobId + "&companyId=" + props?.companyId),
    []
  );

  const url = window.location.origin + "/apply-job/" + urlJobData;

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <Fragment>
      <div
        key={`search` + props?.jobId}
        className={`col-job ${progressStatus[props?.jobProgressStatus]}`}
      >
        {open && (
          <URLDialogbox
            id="copy-url-box"
            keepMounted
            open={open}
            onClose={handleClose}
            value={value}
            url={url}
            jobCode={props?.jobCode}
          />
        )}

        <div className="jc-card-header">
          <div>
            {!!props?.jobProgressStatus ? (
              <FiberManualRecordIcon
                fontSize="small"
                sx={{
                  color:
                    props?.jobProgressStatus === "COMPLETE"
                      ? "#00875A"
                      : props?.jobProgressStatus === "IN_PROGRESS"
                      ? "#faaf00"
                      : "#fc7979",
                }}
              />
            ) : (
              <div className="ml-4"></div>
            )}
            <span>{props?.jobCode}</span>
            <Tooltip title="Get job application url">
              <IconButton onClick={handleClickListItem} size="small">
                <LinkIcon height="20" width="20" />
              </IconButton>
            </Tooltip>
          </div>

          <div className="w-50 justify-content-between">
            <div className="jc-tags">
              {props?.jobStatus ? (
                <span className={`status-label ${color[props?.jobStatus]}`}>
                  {props?.jobStatus[0] +
                    props?.jobStatus.slice(1).toLowerCase().replace("_", " ")}
                </span>
              ) : null}
              {props?.priority ? (
                <span className={`status-label ${priority[props?.priority]}`}>
                  {props?.priority[0] + props?.priority.slice(1).toLowerCase()}
                </span>
              ) : null}
            </div>
            {props?.editAction && (
              <Tooltip title="Edit Job">
                <IconButton
                  size="small"
                  onClick={() => props?.editAction(props?.jobId)}
                >
                  <EditOutlinedIcon color="action" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="jc-card-content">
          <span
            className="title"
            onClick={() => props?.cardClickEvent(props, props.createdAt)}
          >
            {props?.name}
          </span>
          <div
            onClick={() => props?.cardClickEvent(props, props.createdAt)}
            className="show-more"
            dangerouslySetInnerHTML={{
              __html: props?.description?.substring(0, 101),
            }}
          />

          <div
            onClick={() => props?.cardClickEvent(props, props.createdAt)}
            className="job-posted"
          >
            <span>
              Positions : <label>{props?.positions}</label>
            </span>
            <span>
              Days Posted : <label>{getDays(props?.createdAt)}</label>
            </span>
          </div>
          {props?.countData && (
            <div className="card">
              <li
                className="item"
                style={{ padding: 0, width: "100%", marginTop: "10px" }}
              >
                <Tooltip placement="top" title="New Application">
                  <span
                    className={
                      props?.countData?.NEW_APPLICATION > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.NEW_APPLICATION}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Reviewing">
                  <span
                    className={
                      props?.countData?.REVIEWING > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.REVIEWING}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Video Interview">
                  <span
                    className={
                      props?.countData?.VIDEO_INTERVIEW > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.VIDEO_INTERVIEW}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Interviewing">
                  <span
                    className={
                      props?.countData?.INTERVIEWING > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.INTERVIEWING}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Offering">
                  <span
                    className={
                      props?.countData?.OFFERING > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.OFFERING}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Offer Letter">
                  <span
                    className={
                      props?.countData?.OFFER_LETTER > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.OFFER_LETTER}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="PreHire">
                  <span
                    className={
                      props?.countData?.PREHIRE > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.PREHIRE}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="On Boarding">
                  <span
                    className={
                      props?.countData?.ONBOARDING > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.ONBOARDING}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Hire">
                  <span
                    className={
                      props?.countData?.HIRE > 0
                        ? "count pointer green"
                        : "count pointer zero"
                    }
                    style={{ marginRight: 5 }}
                  >
                    {props?.countData?.HIRE}
                  </span>
                </Tooltip>
              </li>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
