import {
  ApplicantIcon,
  ScheduleCalIcon,
  MonitorIcon,
  HandshakeIcon,
  PreHireIcon,
  JobIcon,
  DocumentIcon,
} from "../../../icons";
import Chart from "react-apexcharts";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import { FormControlLabel, MenuItem } from "@mui/material";
import { indigo } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "../../admin/dashboard/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/common/Loader";
import Upcoming from "./Upcoming";

const components = {
  a: ApplicantIcon,
  j: JobIcon,
  p: PreHireIcon,
  jo: HandshakeIcon,
  si: ScheduleCalIcon,
  vi: MonitorIcon,
};
const hireDropdownOption = [
  {
    value: 1,
    name: "By Facility",
  },
  {
    value: 2,
    name: "By Department",
  },
  {
    value: 3,
    name: "By Source",
  },
];
export const Dashboard = () => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [jobCount, setJobCount] = useState(0);
  const [jobDates, setJobDates] = useState([]);
  const [jobValues, setJobValues] = useState([]);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [applicantsDates, setApplicantsDates] = useState([]);
  const [applicantsValues, setApplicantsValues] = useState([]);
  const [jobStatusKey, setJobStatusKey] = useState([]);
  const [jobStatusValue, setJobStatusValue] = useState([]);
  const [jobStatusOption, setJobStatusOption] = useState(1);

  const [hireStatusKey, setHireStatusKey] = useState([]);
  const [hireStatusValue, setHireStatusValue] = useState([]);
  const [hireStatusOption, setHireStatusOption] = useState(1);
  const [selectedHireDropdownOption, setSelectedHireDropdownOption] =
    useState(1);
  const [videoInterviewData, setVideoInterviewData] = useState([]);
  const [recentOfferData, setRecentOfferData] = useState([]);
  const [applicantHotListData, setApplicantHotListData] = useState([]);
  const [interviewList, setInterviewList] = useState();
  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [ajFromDate, setAJFromDate] = useState("");
  const [ajToDate, setAJToDate] = useState("");
  const [tthFromDate, setTthFromDate] = useState("");
  const [tthToDate, setTthToDate] = useState("");

  const jobChartProp = {
    series: [
      {
        name: "jobs",
        data: jobValues,
      },
    ],
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#E6492D"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: jobDates,
      },
      tooltip: {
        x: {
          format: "dd",
        },
      },
      fill: {
        colors: ["#E6492D"],
      },
    },
  };
  const applicantChartProp = {
    series: [
      {
        name: "Applicants",
        data: applicantsValues,
      },
    ],
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth", // smooth stroke
        colors: ["#61BD6E"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: applicantsDates,
      },
      tooltip: {
        x: {
          format: "dd",
        },
      },
      fill: {
        colors: ["#b6dbbb"],
      },
    },
  };
  const jobStatusProp = {
    series: [
      {
        name: jobStatusOption === 1 ? "Application status" : "Job status",
        data: jobStatusValue,
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          distributed: true,
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      colors: [
        "#FF4267",
        "#0890FE",
        "#783EFD",
        "#FFAF2A",
        "#34AA44",
        "#E6492D",
        "#3954A5",
        "#9C27B0",
        "#206629",
        "#FF6885",
      ],
      legend: {
        markers: {
          radius: 12,
        },
        horizontalAlign: "left",
      },
      yaxis: {
        categories: jobStatusKey,
      },
      fill: {
        opacity: 1,
      },

      xaxis: {
        labels: {
          show: false,
        },
      },
    },
  };
  const hireStatusProp = {
    series: [
      {
        name: "Hire",
        data: hireStatusValue,
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          distributed: true,
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      colors: [
        "#FF4267",
        "#0890FE",
        "#783EFD",
        "#FFAF2A",
        "#34AA44",
        "#E6492D",
        "#3954A5",
        "#9C27B0",
        "#206629",
        "#FF6885",
      ],
      legend: {
        markers: {
          radius: 12,
        },
        horizontalAlign: "left",
      },
      yaxis: {
        categories: hireStatusKey,
      },
      fill: {
        opacity: 1,
      },

      xaxis: {
        labels: {
          show: false,
        },
      },
    },
  };
  const MyComponent = ({ ...props }) => {
    const TagName = components[props.tag];
    return (
      <TagName fill={props.fill} width={props.width} height={props.height} />
    );
  };

  useEffect(() => {
    const params = {
      userId: localStorage.getItem("user"),
      companyId: companyData.companyId,
    };

    Api("POST", `Dashboard/GetCompanyDashboardCounts`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          setCardData(res.data.data.dashboardCardDetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    Promise.all([
      getJobChart(),
      getApplicantChart(),
      getApplicantStatusChart(),
      getHireChart(),
      getUpcomingData("VIDEO_INTERVIEW"),
      getUpcomingData("OFFERING"),
      getApplicantHotListData(),
      getUpcomingInterviews(),
      getUpcomingTasks(),
    ])
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  const getUpcomingInterviews = () => {
    const query = `companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}&pageLimit=3&pageNumber=1&OrderbyDirection=desc&OrderBy=createdAt`;

    Api("GET", `Dashboard/GetUpcomingInterviewList?` + query)
      .then((res) => {
        setInterviewList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUpcomingTasks = () => {
    Api(
      "GET",
      `Calendar/GetAllTaskList?scheduledDate=${new Date().toISOString()}&companyId=${
        companyData.companyId
      }&userId=${localStorage.getItem("user")}`
    )
      .then((res) => {
        setUpcomingTasks(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobChart = async (
    fromDate = moment().startOf("month").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    try {
      const reqBody = {
        userId: localStorage.getItem("user"),
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        companyId: companyData.companyId,
      };

      const res = await Api("POST", `Dashboard/CompanyChartsJobs`, reqBody);
      if (res.data.isSuccess === true) {
        const date = [];
        const values = [];

        res.data.data.chartDetails.forEach((rec) => {
          date.push(moment(rec.date).format("ddd, MMM Do YYYY"));
          values.push(rec.value);
        });

        setJobDates(date);
        setJobValues(values);
        setJobCount(res.data.data.count);
      }
    } catch (error) {}
  };

  const getApplicantChart = async (
    fromDate = moment().startOf("months").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    try {
      const reqBody = {
        userId: localStorage.getItem("user"),
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        companyId: companyData.companyId,
      };

      const res = await Api(
        "POST",
        `Dashboard/CompanyChartsApplicant`,
        reqBody
      );

      if (res.data.isSuccess === true) {
        const date = [];
        const values = [];

        res.data.data.chartDetails.forEach((rec) => {
          date.push(moment(rec.date).format("ddd, MMM Do YYYY"));
          values.push(rec.value);
        });

        setApplicantsDates(date);
        setApplicantsValues(values);
        setApplicantsCount(res.data.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getApplicantStatusChart = async (
    param,
    fromDate = moment().startOf("months").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    try {
      setAJFromDate(fromDate);
      setAJToDate(toDate);
      let selectedOption = param || jobStatusOption;
      const reqBody = {
        userId: localStorage.getItem("user"),
        companyId: companyData.companyId,
        selectedOption,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      };

      const res = await Api("POST", `Dashboard/Company_Chart_AS_JS`, reqBody);
      const tempKey = [];
      const tempValues = [];
      if (res.data.response && Object.keys(res.data.response).length) {
        for (const [key, value] of Object.entries(res.data.response)) {
          tempKey.push(key.replace("_", " "));
          tempValues.push({ x: key.replace("_", " "), y: value });
        }
      }
      setJobStatusKey(tempKey);
      setJobStatusValue(tempValues);
    } catch (error) {
      console.log(error);
    }
  };

  const getUpcomingData = async (key) => {
    try {
      const query = `companyId=${
        companyData.companyId
      }&jobCycleStatus=${key}&pageLimit=${
        key !== "VIDEO_INTERVIEW" ? 4 : 3
      }&pageNumber=1&OrderBy=createdAt&OrderbyDirection=desc`;
      const res = await Api(
        "GET",
        `Dashboard/GetCompanyDashboardCardList?` + query
      );
      if (key === "VIDEO_INTERVIEW") setVideoInterviewData(res.data.data);
      else if (key === "OFFERING") setRecentOfferData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getApplicantHotListData = async () => {
    try {
      const query = `companyId=${
        companyData.companyId
      }&type=${"hotlist"}&pageLimit=4&pageNumber=1`;
      const res = await Api("GET", `Job/GetHotandHireApplicantList?` + query);
      const tempArr = [];
      if (res.data.data.length) {
        for (const iterator of res.data.data) {
          tempArr.push({
            ...iterator,
            ...iterator.applicantJobList[0],
            more: iterator.applicantJobList.length - 1,
          });
        }
        setApplicantHotListData(tempArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHireChart = async (
    option,
    dropdown,
    fromDate = moment().startOf("months").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    try {
      setTthFromDate(fromDate);
      setTthToDate(toDate);
      let selectedOption = option || hireStatusOption;
      const reqBody = {
        userId: localStorage.getItem("user"),
        companyId: companyData.companyId,
        isTimeToHire: selectedOption === 1,
        selectedOption: dropdown || 1,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      };

      const res = await Api("POST", `Dashboard/GetCompanyTTH_H`, reqBody);
      const tempKey = [];
      const tempValues = [];
      if (res.data.response && Object.keys(res.data.response).length) {
        for (const [key, value] of Object.entries(res.data.response)) {
          tempKey.push(key);
          tempValues.push({ x: key, y: parseInt(value) });
        }
      }
      setHireStatusKey(tempKey);
      setHireStatusValue(tempValues);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="company_content">
      {isLoading ? (
        <Loader inShow={true} />
      ) : (
        <div className="company_card comp-dashboard">
          {cardData.map((val) => (
            <div className="company_grid dashboard_grid" key={val.tag}>
              <div
                className="grid_1"
                style={{ backgroundColor: val.fill + "26" }}
              >
                <MyComponent
                  tag={val.tag}
                  width="24"
                  height="24"
                  fill={val.fill}
                />
              </div>
              <div className="grid_2 content-table">{val.a}</div>
              <div className="grid_3 company_text">{val.b}</div>
              <div className="grid_4 grid_keys green_text">{val.c}</div>
            </div>
          ))}
          <div className="grid_container">
            {/* jobs chart start */}
            <div className="charts_grid jobs">
              <div className="charts_header">
                <div className="charts_title">
                  <div>
                    <div className="svg-bg">
                      <FontAwesomeIcon
                        icon={faBriefcase}
                        fontSize={"32px"}
                        color={"#3954A5"}
                      />
                    </div>
                  </div>
                  <div className="heading-label grid_keys">
                    Jobs
                    <br />
                    <span className="company_text">{jobCount}</span>
                  </div>
                </div>
                <div className="year_dropdown">
                  <DatePicker handleChange={getJobChart} />
                </div>
              </div>
              <div className="chart_container">
                <Chart
                  options={jobChartProp.options}
                  series={jobChartProp.series}
                  type="area"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            </div>
            {/* jobs chart end */}

            {/* applicants chart start */}
            <div className="charts_grid applicants">
              <div className="charts_header">
                <div className="charts_title">
                  <div>
                    <div className="svg-bg">
                      <MyComponent
                        tag="a"
                        width="43"
                        height="35"
                        fill="#3954A5"
                      />
                    </div>
                  </div>
                  <div className="heading-label grid_keys">
                    Applicants
                    <br />
                    <span className="company_text">{applicantsCount}</span>
                  </div>
                </div>
                <div className="year_dropdown">
                  <DatePicker handleChange={getApplicantChart} />
                </div>
              </div>
              <div className="chart_container">
                <Chart
                  options={applicantChartProp.options}
                  series={applicantChartProp.series}
                  type="area"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            </div>
            {/* applicants chart end */}
          </div>
          <div className="flex_container">
            {/* Application status and Job status chart start */}
            <div className="charts_grid bar_chart">
              <div className="charts_header">
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  defaultValue={jobStatusOption}
                  value={jobStatusOption}
                  sx={{ marginRight: "auto" }}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    getApplicantStatusChart(value, ajFromDate, ajToDate);
                    setJobStatusOption(value);
                  }}
                >
                  <FormControlLabel
                    value={1}
                    label="Application Status"
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={2}
                    label="Job Status"
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
                <div className="year_dropdown">
                  <DatePicker
                    handleChange={(fromDate, endDate) =>
                      getApplicantStatusChart(
                        jobStatusOption,
                        fromDate,
                        endDate
                      )
                    }
                  />
                </div>
              </div>

              <div className="chart_container">
                <Chart
                  options={jobStatusProp.options}
                  series={jobStatusProp.series}
                  type="bar"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </div>
            {/* Application status and Job status chart end */}
            <Upcoming
              heading="Upcoming Interviews"
              borderColor="#ffaf2a"
              data={interviewList}
            />
          </div>
          <div className="flex_container">
            {/* Time to Hire and Hire chart start */}
            <div className="charts_grid bar_chart bar_chart_1">
              <div className="charts_header">
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  defaultValue={1}
                  value={hireStatusOption}
                  sx={{ marginRight: "auto" }}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    getHireChart(
                      value,
                      selectedHireDropdownOption,
                      tthFromDate,
                      tthToDate
                    );
                    setHireStatusOption(value);
                  }}
                >
                  <FormControlLabel
                    value={1}
                    label="Time to Hire"
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={2}
                    label="Hire"
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
                <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                  <Select
                    inputProps={{ "aria-label": "Without label" }}
                    defaultValue={selectedHireDropdownOption}
                    value={selectedHireDropdownOption}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      getHireChart(
                        hireStatusOption,
                        value,
                        tthFromDate,
                        tthToDate
                      );
                      setSelectedHireDropdownOption(value);
                    }}
                  >
                    {hireDropdownOption.map((iter) => (
                      <MenuItem value={iter.value} key={iter.value}>
                        {iter.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="year_dropdown">
                  <DatePicker
                    handleChange={(fromDate, endDate) =>
                      getHireChart(
                        hireStatusOption,
                        selectedHireDropdownOption,
                        fromDate,
                        endDate
                      )
                    }
                  />
                </div>
              </div>

              <div className="chart_container">
                <Chart
                  options={hireStatusProp.options}
                  series={hireStatusProp.series}
                  type="bar"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </div>
            {/* Time to Hire and Hire chart end */}
            <Upcoming
              heading="Upcoming Tasks"
              borderColor="#9C27B0"
              data={upcomingTasks}
            />
          </div>
          <Upcoming
            heading="Video Interviewing"
            borderColor="#e6492d"
            data={videoInterviewData}
          />
          <Upcoming
            heading="Applicant Hot List"
            borderColor="#34aa44"
            data={applicantHotListData}
            viewAll={true}
          />
          <Upcoming
            heading="Recent Offers"
            borderColor="#257afd"
            data={recentOfferData}
          />
        </div>
      )}
      <p className="text-center footer_rights">
        {new Date().getFullYear()} © TalentATS Design - All rights reserved.
      </p>
    </div>
  );
};
