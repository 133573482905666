import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { user_info } from "../../../../helper/constants";
import { API_types } from "../../../../helper/enum";
import { validations } from "../../../../helper/validations";

import Api from "../../../../helper/Api";
import NoRecordsFound from "../../NoRecordsFound";
import Loader from "../../Loader";
import "../../../../assets/css/components/company-detail/_users.css";
import AddIcon from "@mui/icons-material/Add";
import { ButtonUI } from "../../../ui/button";
import { AddUser } from "./add/addUser";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { EclipseLoader } from "../../eclipseLoader";
import { UserInfo } from "./UserInfo";

export function Users({ isCompany }) {
  const [showLoader, setShowLoader] = useState(false);
  const [usersData, setUsersData] = useState(user_info);
  const [usersDataIndi, setUsersDataIndi] = useState([]);
  const [usersRoles, setUsersRoles] = useState();
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const [editMode, setEditMode] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false);
  const [userId, setUserId] = useState("");

  const [allTimeZone, setAllTimeZone] = useState([]);
  const [openUserAddForm, setUserAddForm] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [pageNumber, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [infLoader, setInfLoader] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  // let isactiveUser = isUserActive;
  const errors = {};

  useEffect(() => {
    setIsLoader(true);
    getTimeZone();
    getUsersData(true);
    getUserRoles();
    setFormValues({
      ...formValues,
      emails: [],
      phoneNumbers: [],
      postalAddresses: [],
    });
  }, []);

  useEffect(() => {
    if (pageNumber <= totalPage && pageNumber !== 1) {
      getUsersData();
    }
  }, [pageNumber, totalPage]);

  const getTimeZone = () => {
    Api(API_types.GET, "TimeZone/GetTimeZone?isActive=true")
      .then((response) => {
        if (response.data) {
          setAllTimeZone(response.data);
        } else {
          setAllTimeZone([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const isValidPhoneNumber = (phoneNumber) => {
  //   // Regular expression to validate phone number format
  //   const phoneRegex = /^\d{10}$/;
  //   return phoneRegex.test(phoneNumber);
  // };

  const toggleUserAdd = (anchor, open) => {
    setUserAddForm({ ...openUserAddForm, [anchor]: open });
  };

  const getUserRoles = () => {
    Api(
      "GET",
      `RoleData/GetRoles?companyId=${companyData.companyId}&pageNumber=${pageNumber}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        let userRoles = res.data.data.map(({ roleId, roleName }) => ({
          roleId,
          roleName,
        }));
        setUsersRoles(userRoles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUserValidate = (values) => {
    if (!values.firstName) {
      errors.firstName = validations?.fname?.req;
    }
    if (!values.middleName) {
      errors.middleName = validations?.mname?.req;
    }
    if (!values.lastName) {
      errors.lastName = validations?.lname?.req;
    }
    if (!values.nickName) {
      errors.nickName = validations?.nname?.req;
    }
    if (!values.user_title) {
      errors.user_title = validations?.title?.req;
    }

    if (!values.email_id) {
      errors.email_id = validations?.email?.req;
    }
    if (!values.cell_phone) {
      errors.cell_phone = validations?.mobile?.req;
    }

    if (!values.timeZone) {
      errors.timeZone = validations?.timezone?.req;
    }

    // if (!editMode) {
    if (!values.login_id) {
      errors.login_id = validations?.loginid?.req;
    } else if (values.login_id.length < 4 || values.login_id.length > 15) {
      errors.login_id = validations?.loginid?.custom;
    }
    if (!values.email_id) {
      errors.email_id = "Email address is required";
    } else if (!isValidEmail(values.email_id)) {
      errors.email_id = "Email address is required";
    }
    if (!values.cell_phone) {
      errors.cell_phone = "Mobile No. is required";
    } else if (values.cell_phone.length != 11) {
      errors.cell_phone = "Mobile No. length is not proper";
    }
    if (!values.jobAccess) {
      errors.jobAccess = validations?.job_level?.req;
    }
    // }

    return errors;
  };

  const showItems = (userId, companyId, isActive) => {
    setUserId(userId);
    getData(userId, companyId, isActive);
    // setIsActive(true);
  };

  const getUsersData = (firstSelectDefault) => {
    Api(
      API_types.GET,
      `Users/GetUserList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}&OrderbyDirection=desc&OrderBy=updatedAt&pageLimit=12&pageNumber=${pageNumber}`
    )
      .then((response) => {
        if (response.data.data.length > 0) {
          // Append new data to the existing usersData when pageNumber is greater than 1
          if (pageNumber > 1) {
            setUsersData((prevData) => [...prevData, ...response.data.data]);
          } else {
            // When pageNumber is 1, set the data directly
            setUsersData(response.data.data);
          }

          if (firstSelectDefault) {
            setUserId(response.data.data[0].userId);
            setTotalPage(response.data.totalPage);
            getData(
              response.data.data[0].userId,
              response.data.data[0].companyId
            );
          }
        } else if (pageNumber === 1) {
          setUsersData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoader(false);
        setInfLoader(false);
      });
  };

  const getData = async (userId, companyId) => {
    try {
      const res = await Api(
        API_types.GET,
        `Users/GetUserById?companyId=${companyId}&userId=${userId}`
      );
      setUsersDataIndi(res.data);
      setIsUserActive(res.data.isActive);
      setUserId(res.data.userID);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMore = () => {
    if (pageNumber <= totalPage) {
      setPage((prevPage) => prevPage + 1); // Increment pageNumber
    }
  };

  const handleAddUser = (e) => {
    let emailValue = [];
    if (Array.isArray(formValues.email_id)) {
      emailValue = formValues.email_id;
    } else {
      emailValue = formValues.emails;
    }

    // Determine the correct phone value
    let phoneValue = [];
    if (Array.isArray(formValues.cell_phone)) {
      phoneValue = formValues.cell_phone;
    } else {
      phoneValue = formValues.phoneNumbers;
    }

    const postalAddresses = formValues.postalAddresses
      ? formValues.postalAddresses.map((address) => ({
        address1: address.address1,
        address2: "", // Assuming this is not provided in the form
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
      }))
      : [];

    const params = {
      companyId: companyData.companyId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      middleName: formValues.middleName,
      nickName: formValues.nickName,
      emails: emailValue,
      phoneNumbers: phoneValue,
      loginID: formValues.login_id,
      title: formValues.user_title,
      timeZone: formValues.timeZone,
      roleId: formValues.roles,
      isSendWelcomeMessage: formValues.isSendWelcomeMessage,
      jobAccessLevel: {
        entityId: formValues.jobAccessCheck,
        entityType: formValues.jobAccess,
      },
      postalAddresses: postalAddresses,
    };

    setFormErrors(addUserValidate(formValues));
    if (Object.values(addUserValidate(formValues)).length > 0) {
      return;
    }
    setSubmitting(true);
    // setDisable(true);
    params.emails = [formValues.email_id] || [];
    params.phoneNumbers = [formValues.cell_phone] || [];
    delete params.postalAddresses;
    // Add User API call
    Api(API_types.POST, `Users/AddUser`, params)
      .then((res) => {
        if (res?.data.isSuccess) {
          getUsersData();
          toast.success(res.data.message);
          setUserId(params.userId);
          toggleUserAdd("right", false);
          if (usersDataIndi.length === 0) {
            getData(res.data?.id, params.companyId);
          }
        } else {
          toast.error(res.data.message);
          // setDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Loader inShow={showLoader} />

      <AddUser
        openSidebar={openUserAddForm}
        toggleDrawer={toggleUserAdd}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        allTimeZone={allTimeZone}
        usersDataIndi={usersDataIndi}
        usersRoles={usersRoles}
        setFormErrors={setFormErrors}
        handleFormSubmit={handleAddUser}
        isSubmitting={isSubmitting}
      />

      <div
        className={
          isCompany === true ? "user-container company" : "user-container"
        }
      >
        <div className="w-100 h-100 position-relative d-flex gap-20">
          <Loader inShow={isLoader} />
          <div className="user-list">
            <div className="inner-h user-inf-list users">
              <InfiniteScroll
                height="100%"
                dataLength={usersData?.length || 0}
                next={fetchMore}
                hasMore={usersData?.length !== usersData?.totalRecords}
                loader={
                  infLoader && (
                    <h4 style={{ textAlign: "center" }}>
                      <EclipseLoader />
                    </h4>
                  )
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div>
                  {usersData.length > 0 ? (
                    usersData.map((users) => (
                      <div
                        className={"user-info"}
                        key={users.userId}
                        onClick={() => {
                          showItems(
                            users.userId,
                            users.companyId,
                            users.isActive
                          );
                        }}
                      >
                        <div className="user-header">
                          {users.active ? (
                            <img
                              src={
                                require("../../../../assets/images/user-info-selected.svg")
                                  .default
                              }
                              alt="nav-icon"
                              className="icon profile-icon"
                            />
                          ) : (
                            <img
                              src={
                                require("../../../../assets/images/user-info-default.svg")
                                  .default
                              }
                              alt="nav-icon"
                              className="icon profile-icon"
                            />
                          )}
                          <span className="user-name-active">
                            {users.firstName + " " + users.lastName}
                          </span>
                        </div>
                        <p className="user-name-active">
                          {users.roleDetails?.map((u) => u.roleName)}
                        </p>

                        <p className="user-name-active font-size">
                          <div>{users.emails?.join(", ")}</div>
                          <div>{users.phoneNumbers?.join(", ")}</div>
                        </p>
                      </div>
                    ))
                  ) : (
                    <NoRecordsFound />
                  )}
                </div>
              </InfiniteScroll>
            </div>
            <div className="bottom-btn">
              <ButtonUI
                p="12px"
                mt="20px"
                ml="8px"
                width="300px"
                leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                onClick={() => (
                  // setLabel("Add New User"),
                  setFormErrors({}),
                  setFormValues({}),
                  toggleUserAdd("right", true),
                  setEditMode(false)
                )}
              >
                Add New User
              </ButtonUI>
            </div>
          </div>
          {usersData.length > 0 && (
            <UserInfo
              usersDataIndi={usersDataIndi}
              isUserActive={isUserActive}
              companyData={companyData}
              getUsersData={getUsersData}
              allTimeZone={allTimeZone}
              isCompany={isCompany}
              editMode={editMode}
              setEditMode={setEditMode}
              getData={getData}
              setUserId={setUserId}
              usersRoles={usersRoles}
            />
          )}
        </div>
      </div>
    </>
  );
}