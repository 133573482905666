import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledPagination } from "../ui/pagination/pagination";
import Api from "../../helper/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/inter";
import "../../assets/css/components/add-edit-popup/_add-edit-popup.css";
import "../../assets/css/components/company-view/_company-view.css";
import { AddEditCompany } from "./AddEditCompany";
import { useDispatch } from "react-redux";
import { COMPANY_DATA_ACTIONS } from "../../redux/companyData/actions";
import { ButtonUI } from "../ui/button";
import AddIcon from "@mui/icons-material/Add";
import { EditDeleteMenu } from "../ui/editDeleteMenu";
import { IconButton } from "@mui/material";
import { Tooltip } from "@material-ui/core";
import NoRecordsFound from "../common/NoRecordsFound";
import { JOB_DATA_ACTIONS } from "../../redux/jobData/actions";
import Repeat, { AlertBox, showSuccess } from "../../helper/utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { indigo, red } from "@mui/material/colors";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  skeletonBaseColor,
  skeletonBorderRadius,
  skeletonHighLightColor,
} from "../../helper/constants";

export function CompanyList() {
  const [isLoader, setIsLoader] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [allTimeZone, setAllTimeZone] = useState([]);
  const [sortType, setSorted] = useState("desc");
  const [sortColumn, setSortColumn] = useState("updatedAt");
  const [searchInput, setSearchInput] = useState("");
  const [disable, setDisable] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("Add New");
  const [editMode, setEditMode] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editImageShow, setEditImageShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [view, setView] = useState("list");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expandedCompanyId, setExpandedCompanyId] = useState(null);
  const [imageErrorMsg, setImageErrorMsg] = useState("");
  const [attachment, setAttachment] = useState("");

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const pageLimit = 12;
  const errors = {};

  useEffect(() => {
    getTimeZone();
    setIsLoader(true);
    dispatch({ type: JOB_DATA_ACTIONS.DELETE_ALL_JOBS });
  }, []);

  // Initial API call on component mount
  useEffect(() => {
    getCompanyData(); // Fetches data only once on initial load
  }, []);

  useEffect(() => {
    if (page > 1 || searchInput || sortType !== "desc" || sortColumn !== "updatedAt") {
      getCompanyData();
    }
  }, [page, sortType, sortColumn]);

  const handleAddCompany = () => {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    setDisable(true);
    setIsSubmitting(true);
    Api("POST", "companies/addcompany", formValues, "multipart")
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success(response.data.message);
          getCompanyData("desc", "updatedAt", "");
          toggleAddEditDrawer("right", false);
          setDisable(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleEditCompany = () => {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    setDisable(true);
    setIsSubmitting(true);
    Api("PUT", "companies/editcompany", formValues, "multipart")
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success(response.data.message);
          getCompanyData("desc", "updatedAt", "");
          toggleAddEditDrawer("right", false);
          setDisable(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const toggleExpand = (companyId) => {
    setExpandedCompanyId(companyId === expandedCompanyId ? null : companyId);
  };

  const getCompanyData = () => {
    setIsLoader(true);
    Api(
      "GET",
      `Companies/GetCompany?pageLimit=${pageLimit}&pageNumber=${page}&OrderbyDirection=${sortType}&OrderBy=${sortColumn}&searchText=${searchInput}`
    )
      .then((response) => {
        if (response.data.data.length) {
          setCompanyData(response.data.data);
          setTotalPage(response.data.totalPage);
        } else {
          setCompanyData([]);
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };


  const getTimeZone = () => {
    Api("GET", "TimeZone/GetTimeZone?isActive=true").then((response) => {
      response.data ? setAllTimeZone(response.data) : setAllTimeZone([]);
    });
  };

  // On every page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // On every company search
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
    // setPage(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getCompanyData();
    }
  };

  // Return searched company records
  const getSearchCompany = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      getCompanyData("desc", "updatedAt", searchInput);
      setIsLoader(true);
    }
  };

  // Sort company data
  const handleSort = () => {
    const newSortOrder = sortType === "asc" ? "desc" : "asc";
    setSorted(newSortOrder);
    setSortColumn("name");
    setPage(1);  // Reset to first page when sorting
  };

  // Open Add form
  const addDataTable = () => {
    toggleAddEditDrawer("right", true);
    setEditImageShow(false);
    setEditMode(false);
    setFormValues({});
    setFormErrors({});
    setAddEditLabel("Add New Company");
    setAttachment("");
  };

  const editCompany = (id) => {
    setFormErrors({});
    toggleAddEditDrawer("right", true);
    // Edit button clicked in - popup
    setEditMode(true);
    setAddEditLabel("Edit Company");
    setEditImageShow(true);

    const listItemValue = companyData.filter((items) => {
      return items.companyId === id ? items : null;
    });

    setFormValues({
      ["name"]: listItemValue[0].name,
      ["jobPrefix"]: listItemValue[0].jobPrefix,
      ["timeZone"]: listItemValue[0].timeZone,
      ["totalJob"]: listItemValue[0].totalJob,
      ["perJobBoard"]: listItemValue[0].perJobBoard,
      ["companyLogo"]: listItemValue[0].companyImage,
      ["companyId"]: id,
    });
  };

  const deleteCompany = (id) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      setIsLoader(true);
      // Delete comapny api call
      Api("DELETE", `Companies/DeleteCompany?companyId=${id}`).then((res) => {
        res.data.isSuccess == true
          ? showSuccess("Deleted", res.data.message)
          : toast.error(res.data.message);
        getCompanyData("desc", "updatedAt", "");
      });
    });
  };

  // Validations
  const validate = (values) => {
    if (!values.name) {
      errors.name = "Name is required.";
    }

    if (!values.jobPrefix) {
      errors.jobPrefix = "Job Number Prefix is required.";
    } else if (!isNaN(values.jobPrefix)) {
      errors.jobPrefix = "Job Number Prefix must be text.";
    }

    if (!values.timeZone) {
      errors.timeZone = "Timezone is required.";
    }

    if (!values.totalJob) {
      errors.totalJob = "Total is required.";
    } else if (isNaN(values.totalJob)) {
      errors.totalJob = "Total must be number.";
    }

    if (!values.perJobBoard) {
      errors.perJobBoard = "Per Job Board is required.";
    } else if (isNaN(values.perJobBoard)) {
      errors.perJobBoard = "Per Job Board must be number.";
    }

    if (!values.companyImage && !values.companyLogo) {
      errors.companyImage = "Company Logo is required.";
    }

    return errors;
  };

  const onDetailSources = (id, companyHirerchy) => {
    navigate("/sources", {
      state: { companyId: id, companyHirerchy: companyHirerchy },
    });
    dispatch({
      type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
      payload: { companyId: id, companyHirerchy: companyHirerchy },
    });
  };

  const handleCellClick = (compId, compHierarchy) => {
    navigate("/sources", {
      state: { companyId: compId, companyHirerchy: compHierarchy },
    });
    dispatch({
      type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
      payload: { companyId: compId, companyHirerchy: compHierarchy },
    });
  };

  const displayData = (method) => {
    setView(method);
  };

  const renderCompanyName = (name, companyId) => {
    if (name.length > 70 && companyId === expandedCompanyId) {
      return name;
    } else if (name.length > 70 && companyId !== expandedCompanyId) {
      return name.slice(0, 70) + "...";
    } else {
      return name;
    }
  };

  const CompaniesCard = companyData.map((val, key) => {
    return (
      <div key={key} className="company_grid">
        <div className="grid_1 image grid_values">
          {val.companyImage ? (
            <img
              src={val.companyImage}
              alt="logo"
              className="company_grid_logo"
              width="80px"
              height="70px"
            />
          ) : (
            <img
              alt="logo"
              className="company_grid_logo"
              width="80px"
              height="70px"
              src={require("../../assets/images/no-img.png")}
            />
          )}
        </div>
        <div className="grid_7 grid_values text-right">
          <EditDeleteMenu
            id={val.companyId}
            onEdit={editCompany}
            onDelete={deleteCompany}
          />
        </div>
        <div
          className="grid_2 td_name"
          onClick={() => handleCellClick(val.companyId, val.companyHirerchy)}
        >
          {val.name}
        </div>
        <div className="grid_3 grid_values">
          {val.jobPrefix}
          <br />
          <span className="grid_keys">Job Number Prefix</span>
        </div>
        <div className="grid_4 grid_values">
          {val.timeZoneOffSet}
          <br />
          <span className="grid_keys">Time Zone</span>
        </div>
        <div className="grid_5 grid_values">
          {val.perJobBoard}
          <br />
          <span className="grid_keys">Per Job Board</span>
        </div>
        <div className="grid_6 grid_values">
          {val.totalJob}
          <br />
          <span className="grid_keys">Total</span>
        </div>
      </div>
    );
  });

  const CompaniesList = companyData.map((val, key) => {
    return (
      <tr key={key}>
        <td
          className="td_name"
          {...(val.name.length > 70 && { "data-tooltip": val.name })}
          onClick={() => onDetailSources(val.companyId, val.companyHirerchy)}
        >
          {val.companyImage ? (
            <img
              src={val.companyImage}
              alt="logo"
              className="company_list_logo"
              width="80px"
              height="70px"
            />
          ) : (
            <img
              alt="logo"
              className="company_list_logo"
              width="80px"
              height="70px"
              src={require("../../assets/images/no-img.png")}
            />
          )}
          {renderCompanyName(val.name, val.companyId)}
        </td>
        <td className="td_name">{val.parentCompanyName}</td>
        <td>{val.jobPrefix}</td>
        <td>{val.timeZoneOffSet}</td>
        <td>{val.perJobBoard}</td>
        <td>{val.totalJob}</td>
        <td>
          <Tooltip title="Edit">
            <IconButton onClick={() => editCompany(val.companyId)}>
              <EditOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => deleteCompany(val.companyId)}>
              <DeleteOutlineOutlinedIcon sx={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    );
  });

  const HeaderOfSkeleton = () => {
    return (
      <div className="content_header">
        <div className="company_text mr-10">
          <Skeleton count={1} height={38} width={120} />
        </div>
        <div className="company_search d-flex skeleton">
          <Skeleton count={1} height={38} width={250} className="mr-10" />
          <Skeleton count={1} height={38} width={76} />
        </div>
        <div className="company_sort">
          <Skeleton count={1} height={38} width={38} className="mr-10" />
        </div>
        <div className="company_view">
          <Skeleton count={1} height={38} width={38} className="mr-10" />
        </div>
        <div className="company_view">
          <Skeleton count={1} height={38} width={38} className="mr-10" />
        </div>
        <div className="company_view">
          <Skeleton count={1} height={38} width={76} className="mr-10" />
        </div>
      </div>
    );
  };

  const TableRowSkeleton = ({ count }) => {
    return (
      <Repeat numTimes={count}>
        {(index) => (
          <>
            <tr>
              <td className="td_name">
                <Skeleton count={1} height={40} />
              </td>
              <td className="td_name">
                <Skeleton count={1} height={40} />
              </td>
              <td>
                <Skeleton count={1} height={40} />
              </td>
              <td>
                <Skeleton count={1} height={40} />
              </td>
              <td>
                <Skeleton count={1} height={40} />
              </td>
              <td>
                <Skeleton count={1} height={40} />
              </td>
              <td>
                <Skeleton
                  count={1}
                  height={40}
                  width={40}
                  inline={true}
                  className="mr-10"
                />
                <Skeleton count={1} height={40} width={40} inline={true} />
              </td>
            </tr>
          </>
        )}
      </Repeat>
    );
  };

  const toggleAddEditDrawer = (anchor, open) => {
    setOpenSidebar({ ...openSidebar, [anchor]: open });
    // Call child component's function to reset image error message and clear attachment
    if (!open) {
      resetChildState(); // Call the function passed from parent to child
    }
  };

  // Define the function to reset the image error message and attachment
  const resetChildState = () => {
    setImageErrorMsg(""); // Assuming you have this state defined in the child component
    setAttachment(""); // Assuming you have this state defined in the child component
  };
  const handleSubmit = () => {
    editMode ? handleEditCompany() : handleAddCompany();
  };


  return (
    <div>
      {openSidebar && (
        <AddEditCompany
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          editMode={editMode}
          handleSubmit={handleSubmit}
          label={showAddEditLabel}
          editImageShow={editImageShow}
          setEditImageShow={setEditImageShow}
          allTimeZone={allTimeZone}
          toggleDrawer={toggleAddEditDrawer}
          resetChildState={resetChildState}
          openSidebar={openSidebar}
          isSubmitting={isSubmitting}
          onClose={() => toggleAddEditDrawer("right", false)}
          attachment={attachment}
          setAttachment={setAttachment}
        />
      )}

      <div className="company_content">
        {isLoader === true ? (
          <SkeletonTheme
            borderRadius={skeletonBorderRadius}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighLightColor}
          >
            <HeaderOfSkeleton />
          </SkeletonTheme>
        ) : (
          <div className="content_header">
            <div className="company_text mr-20">Companies</div>
            <div className="company_search">
              <img
                src={require("../../assets/images/search_icon.png")}
                alt="search-icon"
                className="search_img"
              />
              <input
                type="text"
                placeholder="Search Companies"
                className="search_input"
                name="search_input"
                value={searchInput}
                onChange={handleSearch}
                onKeyPress={handleKeyPress}
              />
              <button onClick={getSearchCompany} className="search-btn">
                Search
              </button>
            </div>
            {view === "grid" && (
              <div className="company_sort">
                <div className="sort_text">
                  <span className="text_light"> SORT:</span> A-Z
                </div>
                <button onClick={handleSort} className="icon icon_button">
                  <img
                    src={require("../../assets/images/drop_down.png")}
                    alt="sort-icon"
                    className="icon sort_img"
                  />
                </button>
              </div>
            )}
            <div className="company_view">
              <Tooltip title="Grid view">
                <button
                  type="submit"
                  onClick={() => displayData("grid")}
                  className="icon icon_button"
                >
                  {view === "grid" ? (
                    <img
                      src={require("../../assets/images/select_grid_view.png")}
                      alt="grid-icon"
                    />
                  ) : (
                    <img
                      src={require("../../assets/images/grid_view.png")}
                      alt="grid-icon"
                    />
                  )}
                </button>
              </Tooltip>
              <Tooltip title="List view">
                <button
                  type="submit"
                  onClick={() => displayData("list")}
                  className="icon icon_button"
                >
                  {view === "list" ? (
                    <img
                      src={require("../../assets/images/select_list_view.png")}
                      alt="list-icon"
                    />
                  ) : (
                    <img
                      src={require("../../assets/images/list_view.png")}
                      alt="list-icon"
                    />
                  )}
                </button>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton
                  onClick={() => (
                    getCompanyData("desc", "updatedAt", ""), setSearchInput("")
                  )}
                >
                  <LoopOutlinedIcon sx={{ color: indigo[500] }} />
                </IconButton>
              </Tooltip>
              <ButtonUI
                p="12px"
                ml="5px"
                type="submit"
                onClick={() => addDataTable()}
                leftIcon={<AddIcon fontSize="small" />}
              >
                Add
              </ButtonUI>
            </div>
          </div>
        )}

        {view === "list" ? (
          <div className="company_table">
            <table>
              <thead>
                <tr>
                  <th className="th_name">
                    Companies Name
                    {isLoader === false && (
                      <button onClick={handleSort} className="icon icon_button">
                        <img
                          src={require("../../assets/images/tabsort.png")}
                          alt="sort-icon"
                          className="icon sort_icon"
                        />
                      </button>
                    )}
                  </th>
                  <th className="th_name">Parent Company</th>
                  <th>Job Prefix</th>
                  <th>Time Zone</th>
                  <th>Per Job Board</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoader === true ? (
                  <SkeletonTheme
                    borderRadius={skeletonBorderRadius}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighLightColor}
                  >
                    <TableRowSkeleton count={12} />
                  </SkeletonTheme>
                ) : (
                  CompaniesList
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="company_card pt-10">{CompaniesCard}</div>
        )}
        {!isLoader && (
          <StyledPagination
            count={totalPage}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        )}

        {!isLoader && companyData.length === 0 && (
          <div className="company_content no-rec">
            <NoRecordsFound />
          </div>
        )}
      </div>
    </div>
  );
}
