import { IconButton, Tooltip, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { indigo } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { ReportSortFilter } from "./reportSortFilter";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import moment from "moment";
import jsPDF from "jspdf";

export const TimeToHire = () => {
  const [isLoader, setIsLoader] = useState(true);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [totalPage, setTotalPage] = useState(1);
  const [locationOptions, setLocationOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [deptOptions, setDeptOptions] = useState([]);
  const [tthExportData, setTTHExportData] = useState([]);
  const [orderByDirection, setOrderByDirection] = useState("desc");
  const [filterFlag, setFilterFlag] = useState(0);
  const [toDate, setToDate] = useState(new Date().toISOString());
  const [fromDate, setFromDate] = useState(new Date().toISOString());
  const [dateDisplay, setDateDisplay] = useState("");
  const [elemId, setElemId] = useState("");

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getTimeToHireReport(0);
    getLocationOptions();
    getDepartmentOptions();
    getSubCompanies();
    getTimeToHireReportExportData();
  }, []);

  useEffect(() => {
    getTimeToHireReport();
  }, [pageNumber]);

  // get 1 month data by default
  const getTimeToHireReport = (
    flag = filterFlag,
    elementId = elemId,
    dateFrom = fromDate,
    dateTo = toDate,
    dtDisplay = dateDisplay
  ) => {
    const query = `companyId=${
      companyData.companyId
    }&flag=${flag?.toString()}&searchBy=${elementId}&dateTo=${dateTo}&dateFrom=${dateFrom}&dateDispaly=${dtDisplay}&pageNumber=${pageNumber}&OrderbyDirection=${orderByDirection}`;
    Api("GET", `Report/TimetoHireReport?${query}&pageLimit=${10}`)
      .then((res) => {
        setRows(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getLocationOptions = () => {
    Api("GET", `Facilities/GetAllFacilities?companyId=${companyData.companyId}`)
      .then((res) => {
        setLocationOptions(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartmentOptions = () => {
    Api(
      "GET",
      `Department/GetDepartmentList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setDeptOptions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getSubCompanies = () => {
    Api(
      "GET",
      `Companies/GetCompany?pageLimit=${15}&parentCompanyId=${
        companyData.companyId
      }&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((response) => {
        response.data.data.length
          ? setCompanyOptions(response.data.data)
          : setCompanyOptions([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = () => {
    orderByDirection === "desc"
      ? setOrderByDirection("asc")
      : setOrderByDirection("desc");
    getTimeToHireReport();
  };

  const getTimeToHireReportExportData = (
    flag = filterFlag,
    elementId = elemId,
    dateFrom = fromDate,
    dateTo = toDate,
    dtDisplay = dateDisplay
  ) => {
    const query = `companyId=${
      companyData.companyId
    }&flag=${flag?.toString()}&searchBy=${elementId}&dateTo=${dateTo}&dateFrom=${dateFrom}&dateDispaly=${dtDisplay}&pageNumber=${pageNumber}&OrderbyDirection=${orderByDirection}`;

    Api("GET", `Report/TimetoHireReportExport?${query}`)
      .then((res) => {
        setTTHExportData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (fileName) => {
    Api(
      "GET",
      `Report/TimetoHireReportExport?companyId=${
        companyData.companyId
      }&flag=${filterFlag?.toString()}&searchBy=${elemId}&dateTo=${toDate}&dateFrom=${fromDate}&dateDispaly=${dateDisplay}&OrderbyDirection=${orderByDirection}`
    )
      .then((res) => {
        if (res.data.data == null || res.data.data.length == 0) {
          return;
        }

        const ws = XLSX.utils.json_to_sheet(res.data.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
      });
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const Row = rows?.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val.applicantName}</td>
        <td>{val.jobNameAndNumber}</td>
        <td>{val.companyName}</td>
        <td>{val.departmentName}</td>
        <td>{val.facility}</td>
        <td>{val.source}</td>
        <td>{val.daysToHire}</td>
        <td>{val.termination}</td>
      </tr>
    );
  });

  const exportPDF = () => {
    getTimeToHireReportExportData();

    if (tthExportData == null || tthExportData.length == 0) {
      return;
    }

    var doc = new jsPDF("l", "mm", "a4");

    doc.setFontSize(10);

    doc.text(20, 20, moment().format("MM/DD/YY, HH:mm a"));
    doc.text(260, 20, "TalentATS");

    doc.setFontSize(22);
    doc.text(20, 30, "Time to Hire Report");

    const columns = [
      { title: "Name", dataKey: "Name." },
      { title: "Job Title/Number", dataKey: "Job Title/Number" },
      { title: "Company", dataKey: "Company" },
      { title: "Department", dataKey: "Department" },
      { title: "Facility", dataKey: "Facility" },
      { title: "Source", dataKey: "Source" },
      { title: "Days to Hire", dataKey: "Days to Hire" },
      { title: "Termination", dataKey: "Termination" },
    ];

    var options = {
      margin: {
        top: 20,
      },
      startY: 40,
    };

    doc.setFontSize(10);
    doc.autoTable(columns, tthExportData, options);

    doc.save("Time_To_Hire_Report.pdf");
  };

  const handleSortFilter = (flag, elementId, dateTo, dateFrom, dtDisplay) => {
    setIsLoader(true);
    if (flag == 1) {
      if (
        dateTo === undefined ||
        dateFrom === undefined ||
        dtDisplay === undefined
      ) {
        setFilterFlag("");
        setElemId("");
        setToDate(new Date().toISOString());
        setFromDate(new Date().toISOString());
        setDateDisplay("");
        getTimeToHireReport();
        getTimeToHireReportExportData();
      } else {
        getTimeToHireReport(flag, elementId, dateFrom, dateTo, dtDisplay);
        setFilterFlag(flag);
        setElemId(elementId);
        setToDate(dateTo);
        setFromDate(dateFrom);
        setDateDisplay(dtDisplay);
        getTimeToHireReportExportData(
          flag,
          elementId,
          dateFrom,
          dateTo,
          dtDisplay
        );
      }
    } else {
      if (
        dateTo === undefined ||
        dateFrom === undefined ||
        dtDisplay === undefined
      ) {
        setToDate(new Date().toISOString());
        setFromDate(new Date().toISOString());
        setDateDisplay("");
      } else {
        setToDate(dateTo);
        setFromDate(dateFrom);
        setDateDisplay(dtDisplay);
      }
      setFilterFlag(flag);
      setElemId(elementId);
      getTimeToHireReport(flag, elementId);
      getTimeToHireReportExportData(flag, elementId);
    }
  };

  const handleDateFilters = () => {};

  return (
    <div className="">
      <div className="company_content">
        <div className="d-flex flex-column border-bottom">
          <div className="d-flex report-header">
            <div className="company_text">Time To Hire Report</div>
            <div className="d-flex">
              <ReportSortFilter
                locationOptions={locationOptions}
                deptOptions={deptOptions}
                companyOptions={companyOptions}
                handleSortFilter={handleSortFilter}
                handleDateFilters={handleDateFilters}
                companyId={companyData.companyId}
                setPage={setPage}
              />
              <Tooltip title="Download">
                <IconButton onClick={() => exportToCSV("Time_To_Hire_Report")}>
                  <FileDownloadOutlinedIcon sx={{ color: indigo[500] }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Print PDF">
                <IconButton onClick={() => exportPDF()}>
                  <LocalPrintshopOutlinedIcon sx={{ color: indigo[500] }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="top-url">
            <p>
              <Link to="/reports">Reports &gt; </Link>
              <Link to="/reports/time-to-hire">
                <strong>Time To Hire Report</strong>
              </Link>
            </p>
          </div>
        </div>

        {isLoader === true ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table" style={{ marginTop: 0 }}>
              <table>
                <thead>
                  <tr className="report-titles">
                    <td className="th_name">Name</td>
                    <td>Job Title/Number </td>
                    <td>Company</td>
                    <td>Department</td>
                    <td>Facility</td>
                    <td>Source</td>
                    <td>Days to Hire</td>
                    <td>Termination</td>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
