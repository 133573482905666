import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { CheckboxList } from "../../../../CheckboxList";
import { TextField, Button, IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Define the email regex for validation

export const ReviewerForm = ({
  openForm,
  toggleForm,
  applicantId,
  formValues,
  setFormValues,
  saveReviewer,
  isSubmitting,
  childCompanyId,
}) => {
  const [allData, setAllData] = useState([]);
  const [reviewerForms, setReviewerForms] = useState([]);
  const [reviewerIds, setReviewerIds] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(true); // New loading state
  const [hasData, setHasData] = useState(true); // New state to track if data exists

  const { companyData } = useSelector((state) => state.companyDataReducer);

  useEffect(() => {
    setLoading(true); // Start loading when fetching data
    Api("GET", `Job/GetTypeWiseUserList?companyId=${childCompanyId}&type=2`)
      .then((res) => {
        const data = res.data.map((rec) => ({
          id: rec.userId,
          name: `${rec.userFirstName} ${rec.userLastName}`,
          userEmailAddress: rec.userEmailAddress,
          checked: false,
        }));
        setAllData(data);
        setHasData(data.length > 0); // Set hasData to true if records are found
        setLoading(false); // Stop loading after the data is fetched
      })
      .catch((error) => {
        setLoading(false); // Stop loading on error
        setHasData(false); // Set hasData to false on error
      });
  }, [childCompanyId]);


  const handleChange = (checkName, isChecked) => {
    const updatedData = allData.map((data) =>
      data.id === checkName ? { ...data, checked: isChecked } : data
    );
    const checkedDocs = updatedData
      .filter((rec) => rec.checked)
      .map((rec) => rec.id);
    setReviewerIds(checkedDocs);
    setFormValues({ ...formValues, ["reviewerListIds"]: checkedDocs });

    setAllData(updatedData);
  };

  const handleAddReviewer = () => {
    const newForm = {
      firstName: "",
      lastName: "",
      title: "",
      emailAddress: "",
    };
    setReviewerForms([...reviewerForms, newForm]);
  };

  const handleClose = (index) => {
    const updatedForms = [...reviewerForms];
    updatedForms.splice(index, 1);
    setReviewerForms(updatedForms);
    setErrorMessages((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[index]; // Remove errors for this form
      return updatedErrors;
    });
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedForms = [...reviewerForms];

    // Update the current reviewer's form based on input change
    updatedForms[index] = { ...updatedForms[index], [name]: value };
    setReviewerForms(updatedForms);

    // Validate the current reviewer form
    const currentReviewer = updatedForms[index];
    const { firstName, lastName, emailAddress } = currentReviewer;

    // Create a new reviewer object
    const isFirstNameValid = firstName.trim() !== "";
    const isLastNameValid = lastName.trim() !== "";
    const isEmailValid = emailRegex.test(emailAddress);

    let nameError = "";
    let emailError = "";

    // if (!isFirstNameValid && !isLastNameValid) {
    //   nameError = "Please enter at least First Name or Last Name!";
    // }
    // if (!emailAddress && !isEmailValid) {
    //   emailError = "Please add a valid email!";
    // }

    // Update error messages in state
    const newErrorMessages = { ...errorMessages };
    if (nameError) {
      newErrorMessages[index] = { ...newErrorMessages[index], name: nameError };
    } else {
      delete newErrorMessages[index]?.name; // Clear name error if valid
    }

    if (emailError) {
      newErrorMessages[index] = { ...newErrorMessages[index], email: emailError };
    } else {
      delete newErrorMessages[index]?.email; // Clear email error if valid
    }

    setErrorMessages(newErrorMessages);

    // Update the reviewer list only if the form is valid
    if (isEmailValid && (isFirstNameValid || isLastNameValid)) {
      const updatedReviewerList = updatedForms.filter(
        reviewer => reviewer.firstName || reviewer.lastName || reviewer.emailAddress
      );
      setFormValues({ ...formValues, reviewerList: updatedReviewerList });
    } else {
      // Reset reviewer list if invalid
      setFormValues({ ...formValues, reviewerList: [] });
    }
  };

  const handleSubmit = () => {
    const newErrorMessages = {};
    const {
      reviewerListIds = [], // Default to an empty array if reviewerListIds is undefined
      reviewerList = [], // Default to an empty array if reviewerListIds is undefined
    } = formValues;
    // Check if any reviewers are selected
    if (reviewerIds.length === 0 && reviewerForms.length === 0) {
      setErrorMessages({ general: "Please select at least one reviewer!" });
      return;
    }

    // Validate reviewer forms
    reviewerForms.forEach((form, index) => {
      if (form.firstName.trim() === "" && form.lastName.trim() === "") {
        newErrorMessages[index] = { ...newErrorMessages[index], name: "Please enter First Name or Last Name." };
      }
      if (form.emailAddress.trim() === "") {
        newErrorMessages[index] = { ...newErrorMessages[index], email: "Please enter Email." };
      } else if (!emailRegex.test(form.emailAddress)) { // Use the emailRegex defined above
        newErrorMessages[index] = { ...newErrorMessages[index], email: "Please enter a valid Email." };
      }
    });

    // Check for any validation errors
    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages);
      return;
    }

    // Prepare payload for submission
    const payload = {
      jobApplicantVideoInterviewId: formValues.jobApplicantVideoInterviewId,
      reviewerListIds: reviewerIds,
      reviewerList: reviewerForms,
    };

    // Call the function passed from the parent component
    saveReviewer(payload);

    // Reset form values after successful submission
    setReviewerForms([]);
    setReviewerIds([]);
    setFormValues({ reviewerListIds: [] });
    setErrorMessages({}); // Clear error messages
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      title="Select Reviewers"
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        {loading ? (
          <div className=""
            style={{
              minWidth: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </div>
        ) : hasData ? (
          <CheckboxList
            allData={allData}
            setAllData={setAllData}
            showSaveContainer={false}
            handleChange={handleChange}
          />
        ) : (
          <p>No records found</p>
        )}
      </div>

      {errorMessages.general && <p className="error-msg">{errorMessages.general}</p>}

      <span className="participants pointer" onClick={handleAddReviewer}>
        <AddIcon fontSize="small" /> Add Reviewer
      </span>

      {reviewerForms.map((form, index) => (
        <div className="add-upload-container" key={index}>
          <div className="text-right">
            <IconButton size="small" onClick={() => handleClose(index)}>
              <CloseIcon color="primary" fontSize="small" />
            </IconButton>
          </div>
          <div className="forms-input sections">
            <div className="col-2">
              <label>First Name:</label>
              <TextField
                name="firstName"
                value={form.firstName}
                onChange={(event) => handleInputChange(event, index)}
                required
                error={!!errorMessages[index]?.name}
                helperText={errorMessages[index]?.name}
              />
            </div>

            <div className="col-2">
              <label>Last Name:</label>
              <TextField
                name="lastName"
                value={form.lastName}
                onChange={(event) => handleInputChange(event, index)}
                required
                error={!!errorMessages[index]?.name}
                helperText={errorMessages[index]?.name}
              />
            </div>
          </div>

          <div className="forms-input sections">
            <div className="col-2">
              <label>Title:</label>
              <TextField
                name="title"
                value={form.title}
                onChange={(event) => handleInputChange(event, index)}
              />
            </div>

            <div className="col-2">
              <label>Email Address:</label>
              <TextField
                name="emailAddress"
                value={form.emailAddress}
                onChange={(event) => handleInputChange(event, index)}
                required
                error={!!errorMessages[index]?.email}
                helperText={errorMessages[index]?.email}
              />
            </div>
          </div>
        </div>
      ))}

      <Button onClick={handleSubmit} disabled={isSubmitting}>
        {/* {isSubmitting ? "Saving..." : "Save Reviewers"} */}
      </Button>
    </AddEditCol>
  );
};
