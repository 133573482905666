import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PublicNavigationBar } from "../../../components/common/navigationBar/public";
import Api from "../../../helper/Api";
import "../../../assets/css/common/_public.css";
import { ApplyForJob } from "../../../components/common/layouts/ApplyForJob";
import { ButtonUI } from "../../../components/ui/button/index";
import { validations } from "../../../helper/validations";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { grey } from "@mui/material/colors";

export const ViewJob = () => {
  const params = useParams();
  const [jobData, setJobData] = useState({});
  const [isFormOpen, setFormOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [jobId, setJobId] = useState("");
  const formData = new FormData();
  const errors = {};

  useEffect(() => {
    let data = window.atob(params.token);
    let values = data.split("&");
    let urlParams = [];

    values.map((d) => {
      let t = d.split("=");
      urlParams.push(t[1]);
    });

    setJobId(urlParams[0]);

    Api("GET", `Job/ViewJob?jobId=${urlParams[0]}&companyId=${urlParams[1]}`)
      .then((res) => {
        setJobData(res.data.data.jobDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openApplicationForm = () => {
    setFormOpen(true);
  };

  const validate = (values) => {
    if (values?.applicantPhoneList && values?.applicantPhoneList.length > 0) {
      let data = [...values.applicantPhoneList];
      data.map((rec) => {
        if (!rec.phone) {
          errors.phoneNumber = validations?.phoneNumber.req;
        } else if (rec.phone.length != 11) {
          errors.phoneNumber = validations?.phoneNumber.invalid;
        }
      });
    }

    if (!values.firstName) {
      errors.firstName = validations?.fname?.req;
    }

    if (!values.emailAddress && !values.phoneNumber) {
      errors.emailAddress = "Please add at least one contact detail!";
    }

    if (
      values.emailAddress &&
      !values.emailAddress.match(validations?.email?.pattern.value)
    ) {
      errors.emailAddress = validations?.email?.pattern?.message;
    }

    if (!values.lastName) {
      errors.lastName = validations?.lname?.req;
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    setSubmitting(true);
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("emailAddress", formValues?.emailAddress ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("companyId", jobData.companyId);
    formData.append("jobId", jobId);
    formData.append("address.addressLineOne", formValues?.addressLineOne ?? "");
    formData.append("address.addressLineTwo", formValues?.addressLineTwo ?? "");
    formData.append("address.country", "");
    formData.append("askForConfirmation", 1);
    formData.append("address.city", formValues?.city ?? "");
    formData.append("address.state", formValues?.state ?? "");
    formData.append("address.zipCode", formValues?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("POST", `Job/AddApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          setFormOpen(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <PublicNavigationBar />
      {isFormOpen === false ? (
        <div className="job-data-container">
          <div className="header">
            <img
              src={
                jobData.companyLogo
                  ? jobData.companyLogo
                  : require("../../../assets/images/dummy-img.png")
              }
              alt="logo"
              width={200}
              height={200}
              className="company-logo"
            />
            <strong>{jobData.companyName}</strong>
            <span>
              {jobData.jobName} ({jobData.jobNo})
            </span>
          </div>
          <div className="j-content">
            <label>{jobData.companyName}</label>
            <div>
              <label>Position Title :</label> {jobData.jobName}
            </div>
            <div className="mt-20">
              <label>Job Description : </label>
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(jobData.jobDescription),
                }}
              />
            </div>
            <div className="mt-10">
              <ButtonUI p="10px" border="4px" onClick={openApplicationForm}>
                Apply for this job
              </ButtonUI>
            </div>
          </div>
        </div>
      ) : (
        <div className="job-data-container apply-for-job">
          <ApplyForJob
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            isEdit={false}
            showSource={false}
          />
          {isSubmitting === true ? (
            <ButtonUI p="10px" border="4px">
              <CircularProgress sx={{ color: grey[100] }} />
            </ButtonUI>
          ) : (
            <ButtonUI p="10px" border="4px" onClick={handleSubmit}>
              Submit
            </ButtonUI>
          )}
        </div>
      )}
    </div>
  );
};
