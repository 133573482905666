import React from "react";
import AddIcon from "@mui/icons-material/Add";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckboxWithBlueCheck } from "../../../../../../ui/checkbox/checkboxWithBlueTick";
import { ButtonUI } from "../../../../../../ui/button";
import { useState } from "react";
import { AddChecklistItem } from "./addChecklistItem";
import { useEffect } from "react";
import Api from "../../../../../../../helper/Api";
import { API_types } from "../../../../../../../helper/enum";
import { toast } from "react-toastify";
import { validations } from "../../../../../../../helper/validations";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import IconButton from "@mui/material/IconButton";
import { blue } from "@mui/material/colors";
import { NoColDataFound } from "../../../../../NoColDataFound";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";

export const CheckListTab = ({ applicantData, selectedSuitCase }) => {
  const [openSidebar, setSidebar] = useState(false);
  const [checkListData, setCheckListData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const errors = {};

  const toggleDrawer = (open) => {
    setSidebar(open);
  };

  useEffect(() => {
    getApplicantCheckList();
  }, []);

  const getApplicantCheckList = () => {
    setLoader(true);
    const params = {
      applicantId: applicantData.applicantId,
      companyId: applicantData.companyId,
      jobId: selectedSuitCase.jobId,
    };
    Api("POST", `Job/GetApplicantCheckList `, params)
      .then((res) => {
        if (res.data.isSuccess) {
          if (res.data.data.lstApplicantCheckListDetails.length) {
            setCheckListData(res.data.data.lstApplicantCheckListDetails);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const onCheckBoxChange = (checkName, isChecked) => {
    const checkList = checkListData.map((data) => {
      if (data.applicantCheckListId === checkName) {
        data.isChecked = isChecked;

        const params = {
          applicantCheckListId: checkName,
          operationFlag: 1,
        };

        Api("POST", `Job/ApplicantChecklistDisabledChecked`, params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getApplicantCheckList();
              //toast.success(res.data.message);
            } else {
              //toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return data;
    });
    setCheckListData(checkList);
  };

  // const onEyeIconClick = (checkName, currentDisabledState) => {
  //   console.log(currentDisabledState, "currentDisabledState")

  //   const eyeList = checkListData.map((data) => {
  //     if (data.applicantCheckListId === checkName) {
  //       // Toggle the isDisabled state
  //       const updatedDisabledState = !currentDisabledState;
  //       console.log(updatedDisabledState, "updatedDisabledState")

  //       // Prepare the API parameters with the correct operationFlag
  //       const params = {
  //         applicantCheckListId: checkName,
  //         operationFlag: currentDisabledState ? 0 : 1, // 1 to disable, 0 to enable
  //       };
  //       console.log(params, "params")


  //       // API call to update the checklist item's disabled state
  //       Api("POST", "Job/ApplicantChecklistDisabledChecked", params)
  //         .then((res) => {
  //           if (res.data.isSuccess === true) {
  //             getApplicantCheckList(); // Refresh the checklist after updating
  //           } else {
  //             console.log(res.data.message);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });

  //       // Update the local state
  //       data.isDisabled = updatedDisabledState;
  //     }
  //     return data;
  //   });

  //   setCheckListData(eyeList);
  // };

  const onEyeIconClick = (checkName, isCurrentlyDisabled) => {
    // Determine the operationFlag based on the current disabled state
    const operationFlag = isCurrentlyDisabled ? 0 : 1;
    const updatedCheckList = checkListData.map((item) => {
      if (item.applicantCheckListId === checkName) {
        return {
          ...item,
          isDisabled: !isCurrentlyDisabled, // Toggle the disabled state in the UI immediately
        };
      }
      return item;
    });

    setCheckListData(updatedCheckList); // Update the UI

    // Perform the API call
    const params = {
      applicantCheckListId: checkName,
      operationFlag: 0, // Pass the determined operationFlag
    };

    Api("POST", "Job/ApplicantChecklistDisabledChecked", params)
      .then((res) => {
        if (res.data.isSuccess) {
          // If API call fails, revert the optimistic update
          // toast.error(res.data.message);
          if (res.data.message.includes("disabled")) {
            toast.error(res.data.message); // Show success toast for disabling
          } else if (res.data.message.includes("enabled")) {
            toast.success(res.data.message); // Show success toast for enabling
          }
          getApplicantCheckList(); // Re-fetch the data
        }
      })
      .catch((err) => {
        console.error(err);
        // Re-fetch the data if there was an error
        getApplicantCheckList();
      });
  };




  const handleSubmit = (e, checklistId) => {
    const params = {
      companyId: applicantData.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantData.applicantId,
      checklistid: checklistId,
      applicantChecklistName: formValues.newItem,
    };

    setFormErrors(validate(params));
    if (Object.keys(validate(params)).length > 0) {
      return;
    }

    setSubmitting(true);
    Api(API_types.POST, `Job/AddApplicantChecklist`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getApplicantCheckList();
          setFormValues([]);
          toggleDrawer(false);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // Validations
  const validate = (values) => {
    const errors = {};

    // Either checklistId or applicantChecklistName is required
    if (!values.checklistid && !values.applicantChecklistName) {
      errors.checklisttId = validations?.checklisttId?.req;
      errors.newItem = validations?.newItem?.req;
    }

    return errors;
  };


  return (
    <div className="tab-container">
      {openSidebar ? (
        <AddChecklistItem
          toggleForm={toggleDrawer}
          openForm={openSidebar}
          handleSubmit={handleSubmit}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          applicantId={applicantData.applicantId}
          isSubmitting={isSubmitting}
        />
      ) : null}

      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : checkListData.length > 0 ? (
        <>
          <div className="notes-header-container p-0-16 mt-15 justify-content-between">
            <span className="heading-label font-blue">
              Checklist: ({checkListData.length})
            </span>
            <ButtonUI
              p="8px"
              radius="4px"
              leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
              onClick={() => (
                toggleDrawer(true), setFormValues({}), setFormErrors({})
              )}
            >
              Add
            </ButtonUI>
          </div>
          <div className="notes-cards-container p-16">
            {checkListData.map((note) => (
              <div
                className="checkbox-container applicants"
                key={note.applicantCheckListId}
              >
                <div className="ml-10 checkbox-eye">
                  <FormControlLabel
                    control={
                      <CheckboxWithBlueCheck
                        disableRipple
                        checked={note.isChecked}
                        disabled={note.isDisabled}
                        onChange={(e) =>
                          onCheckBoxChange(
                            note.applicantCheckListId,
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={note.name}
                  />

                  <IconButton aria-label="eye" size="small">
                    {console.log("12", note.isDisabled, note.name)}
                    {note.isDisabled === false ? (
                      <VisibilityOffOutlinedIcon
                        style={{ color: blue[700] }}
                        onClick={() => onEyeIconClick(note.applicantCheckListId, false)}
                      />
                    ) : (
                      <RemoveRedEyeOutlinedIcon
                        style={{ color: blue[500] }}
                        onClick={() => onEyeIconClick(note.applicantCheckListId, true

                        )}
                      />
                    )}
                  </IconButton>

                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="notes-header-container p-0-16 mt-15">
            <span className="heading-label font-blue">
              Checklist: ({checkListData.length})
            </span>
            <ButtonUI
              p="8px"
              radius="4px"
              leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
              onClick={() => (
                toggleDrawer(true), setFormValues({}), setFormErrors({})
              )}
            >
              Add
            </ButtonUI>
          </div>
          <NoColDataFound />
        </>
      )}
    </div>
  );
};
