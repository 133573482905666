import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import { HireForm } from "./hireForm";
import { ActionForm } from "./actionForm";
import moment from "moment";
import { APPLICANT_DATA_ACTIONS } from "../../../../../../../redux/applicantData/actions";
import { useDispatch } from "react-redux";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { baseUrl } from "../../../../../../../helper/constants";

export const HireStatus = ({ selectedSuitCase, applicantId }) => {
  const dispatch = useDispatch();
  const [openHireForm, setHireForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [hireList, setHireList] = useState([]);
  const [openActionForm, setActionForm] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isOfferingAcceptaed, setOfferAccepted] = useState(false);
  const [flag, setFlag] = useState("");

  useEffect(async () => {
    var the_arr = baseUrl.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    connect.on("HiredApplicantList", (res) => {
      setHireList(res);
    });

    await connect.start();
  }, [selectedSuitCase.jobId]);

  useEffect(() => {
    getHireList();
  }, []);

  const toggleHireForm = (open) => {
    setHireForm(open);
  };

  const toggleActionForm = (open) => {
    setActionForm(open);
  };

  const getHireList = () => {
    Api(
      "GET",
      `Job/ListHiredApplicant?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setHireList(res.data.data.hiredApplicantList);
        setOfferAccepted(res.data.data.isOfferingAcceptaed);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveHireForm = () => {
    const params = {
      jobId: selectedSuitCase.jobId,
      companyId: selectedSuitCase.companyId,
      applicantId: applicantId,
      hiredDate: formValues?.hiredDate ?? new Date().toISOString(),
      startdDate: formValues?.startdDate ?? new Date().toISOString(),
      notes: formValues?.notes ?? "",
    };

    setSubmitting(true);

    Api("POST", `Job/HiredApplicant`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
          toast.success(res.data.message);
          toggleHireForm(false);
          getHireList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const saveAction = (note, sdate) => {
    const params = {
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
      hiredApplicantId: hireList[0]?.hiredApplicantId,
      closeDate: sdate ?? new Date().toISOString(),
      notes: note,
      flag: flag === "terminate" ? "1" : flag === "quit" ? "2" : "3",
    };

    setSubmitting(true);
    Api("POST", `Job/CloseHiredApplicant`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
          toast.success(res.data.message);
          toggleActionForm(false);
          getHireList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      {openHireForm && (
        <HireForm
          openForm={openHireForm}
          toggleForm={toggleHireForm}
          applicantId={applicantId}
          formValues={formValues}
          setFormValues={setFormValues}
          saveHireForm={saveHireForm}
          isSubmitting={isSubmitting}
        />
      )}

      {openActionForm && (
        <ActionForm
          openForm={openActionForm}
          toggleForm={toggleActionForm}
          applicantId={applicantId}
          saveAction={saveAction}
          isSubmitting={isSubmitting}
          flag={flag}
        />
      )}

      {isOfferingAcceptaed === true && hireList.length === 0 ? (
        <ButtonUI p="9px" ml="96px" onClick={() => toggleHireForm(true)}>
          Hire Applicant
        </ButtonUI>
      ) : isOfferingAcceptaed === true && hireList.length > 0 ? (
        <div className="mt-10 border-bottom">
          <div className="d-flex flex-column font-12">
            <strong>
              <span style={{ marginRight: "5px" }}>Employee :</span>
              {hireList[0]?.hiredApplicantStatus === "QUITE"
                ? "QUIT"
                : hireList[0]?.hiredApplicantStatus}
            </strong>
            <span>
              <strong>Start Date : </strong>
              {moment(hireList[0]?.startDate).format("MM/DD/YYYY")}
            </span>
            {hireList[0]?.hiredApplicantStatus === "TERMINATED" && (
              <span>
                <strong>Terminated Date : </strong>
                {moment(hireList[0].terminatedDate).format("MM/DD/YYYY")}
              </span>
            )}
            <span>
              <strong>Notes :</strong>
              {hireList[0]?.notes}
            </span>
          </div>

          {hireList[0]?.hiredApplicantStatus === "QUITE" ||
            hireList[0]?.hiredApplicantStatus === "FURLOUGH" ||
            hireList[0]?.hiredApplicantStatus === "TERMINATED" ? (
            <ButtonUI
              p="9px"
              ml="96px"
              mt="10px"
              mb="10px"
              onClick={() => toggleHireForm(true)}
            >
              Re-Hire Applicant
            </ButtonUI>
          ) : (
            <div>
              <ButtonUI
                p="9px"
                ml="100px"
                mt="10px"
                onClick={() => (toggleActionForm(true), setFlag("terminate"))}
              >
                Terminate
              </ButtonUI>

              <ButtonUI
                p="9px"
                ml="120px"
                mt="10px"
                onClick={() => (toggleActionForm(true), setFlag("quit"))}
              >
                Quit
              </ButtonUI>

              <ButtonUI
                p="9px"
                ml="105px"
                mt="10px"
                mb="10px"
                onClick={() => (toggleActionForm(true), setFlag("furlough"))}
              >
                Furlough
              </ButtonUI>
            </div>
          )}
        </div>
      ) : (
        isOfferingAcceptaed === false && (
          <div className="d-flex flex-column">
            <strong>Hired</strong>
            <span className="font-12">
              This applicant does not have an accepted offer.
            </span>
          </div>
        )
      )}
    </div>
  );
};
