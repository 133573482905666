import { AddEditPopup } from "./index";
import * as React from "react";
import { useState, useEffect } from "react";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { validations } from "../../../helper/validations";
import { sortAtoZ } from "../../../helper/constants";
import FroalaEditor from "react-froala-wysiwyg";

const config = {
  extraPlugins: "smiley,placeholder,justify",
  removePlugins: "pastefromword, about",
};

export const AddEditJob = (props) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const { jobAllStatus } = useSelector((state) => state.jobDataReducer);
  const [companyList, setCompanyList] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [rateTypes, setRateTypes] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [videoInterviews, setVideoInterviews] = useState([]);
  const [jobTemplates, setJobTemplates] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [description, setDescription] = useState(
    props?.formValues?.description
  );

  const errors = {};

  useEffect(() => {
    if (props.openSidebar.right === true) {
      setFormErrors({});
      getAllApiData();
      setDescription(props.formValues.description);
    }
  }, [props.openSidebar]);

  const getAllApiData = async () => {
    try {
      const [
        allCompanies,
        allFacilities,
        allDepartments,
        allSources,
        allJobTemplates,
        allVideoInterviews,
      ] = await Promise.all([
        Api(
          "GET",
          `Companies/GetCompany?parentCompanyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
        Api(
          "GET",
          `Facilities/GetAllFacilities?companyId=${companyData.companyId}`
        ),
        Api(
          "GET",
          `Department/GetDepartmentList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
        Api(
          "GET",
          `Sources/GetSourceList?type=all&companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
        Api(
          "GET",
          `JobTemplate/GetAllJobTemplate?companyId=${companyData.companyId}`
        ),
        Api(
          "GET",
          `VideoInterview/GetVideoInterviewList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
      ]);

      const companyRecord = {
        companyId: companyData.companyId,
        name: localStorage.getItem("companyName"),
      };

      allCompanies.data.data.push(companyRecord);
      const cData = sortAtoZ(allCompanies?.data?.data);
      setCompanyList(cData);

      const fData = sortAtoZ(allFacilities?.data?.data, "address");
      setFacilities(fData);

      const dData = sortAtoZ(allDepartments?.data);
      setDepartments(dData);

      const jtData = sortAtoZ(allJobTemplates?.data?.data);
      setJobTemplates(jtData);

      const viData = sortAtoZ(allVideoInterviews?.data?.data);
      setVideoInterviews(viData);

      const sourcesData = allSources.data.reduce(function (rv, x) {
        (rv[x["type"]] = rv[x["type"]] || []).push(x);
        return rv;
      }, {});

      const rData = sortAtoZ(sourcesData?.ratetypes);
      setRateTypes(rData);

      const sData = sortAtoZ(sourcesData?.shifttypes);
      setShiftTypes(sData);

      const schData = sortAtoZ(sourcesData?.scheduletypes);
      setScheduleTypes(schData);

      const eData = sortAtoZ(sourcesData?.employmenttypes);
      setEmploymentTypes(eData);
      setLoading(false);
    } catch {
      throw Error("Promise failed");
    }
  };

  const handleEditor = (e) => {
    setDescription(e);
  };

  const validate = (values) => {
    if (!values.companyId) {
      errors.companyId = validations?.companyId?.req;
    }

    if (!values.name) {
      errors.name = validations?.name?.req;
    }

    if (!values.facilityId || values.facilityId === "none") {
      errors.facilities = validations?.facilities?.req;
    }

    if (!description) {
      errors.description = validations?.description?.req;
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (description === undefined) {
      setDescription(props.formValues.description);
    }

    setFormErrors(validate({ ...props.formValues, description }));
    if (
      Object.keys(validate({ ...props.formValues, description })).length > 0
    ) {
      return;
    }

    props.setFormValues({ ...props.formValues, description });
    props.handleSubmit(description);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    props.setFormValues({ ...props.formValues, [name]: value });

    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleJobTempChange = (e) => {
    setLoading(true);
    Api(
      "GET",
      `JobTemplate/GetJobTemplateById?companyId=${companyData.companyId}&jobTemplateId=${e.target.value}`
    )
      .then((res) => {
        const newFormValues = {
          ...props.formValues,
          name: res?.data?.description?.name,
          positions:
            props?.formValues?.positions > 0 ? props?.formValues?.positions : 1,
          appPerJobBoard: res?.data?.description?.appPerJobBoard,
          description: res?.data?.templateData?.description,
          facilityId: res?.data?.description?.facilityId,
          departmentId: res?.data?.description?.departmentId,
          rate: res?.data?.description?.rate,
          rateTypeId: res?.data?.description?.rateTypeId,
          shiftTypeId: res?.data?.description?.shiftTypeId,
          scheduleTypeId: res?.data?.description?.scheduleTypeId,
          employmentTypeId: res?.data?.description?.employmentTypeId,
          videoInterviewId: res?.data?.description?.videoInterviewId,
          previousEmp: res?.data?.description?.previousEmp,
          totalApplication: res?.data?.description?.totalApplication,
          jobTemplateId: res?.data?.description?.jobTemplateId,
        };
        props.setFormValues(newFormValues);
        setDescription(newFormValues.description);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AddEditPopup
      title={props.title}
      openSidebar={props.openSidebar}
      toggleDrawer={props.toggleDrawer}
      handleSubmit={handleSubmit}
      isSubmitting={props.isSubmitting}
      isLoading={isLoading}
      type="jobTemplate"
    >
      <div className="forms-input">
        <label>Company:</label>
        <select
          name="companyId"
          id="companyId"
          onChange={handleChange}
          value={props?.formValues?.companyId}
        >
          <option value="">Select</option>
          {companyList?.map((company) => (
            <option value={company.companyId} key={company.companyId}>
              {company.name}
            </option>
          ))}
        </select>
        <span className="error_msg">{formErrors?.companyId}</span>
      </div>

      <div className="forms-input">
        <label>Job Template:</label>
        <select
          name="jobTemplate"
          id="jobTemplate"
          onChange={handleJobTempChange}
          value={props?.formValues?.jobTemplateId}
        >
          <option value="">Select</option>
          {jobTemplates?.map((jobTemp) => (
            <option key={jobTemp.jobTemplateId} value={jobTemp.jobTemplateId}>
              {jobTemp.name}
            </option>
          ))}
        </select>
      </div>

      <div className="forms-input sections">
        <div className="col-3">
          <label>Positions:</label>
          <input
            className="forms-inputinputnumber"
            type="number"
            name="positions"
            id="positions"
            placeholder="Position here"
            onChange={handleChange}
            value={props?.formValues?.positions ?? ""}
            min="0"
          />
          <span className="error_msg">{formErrors?.positions}</span>
        </div>

        <div className="col-3">
          <label>Priority:</label>
          <select
            name="priority"
            id="priority"
            value={props?.formValues?.priority}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {jobAllStatus?.PRIORITY?.map((priority) => (
              <option value={priority.id} key={priority.id}>
                {priority.description}
              </option>
            ))}
          </select>
        </div>

        <div className="col-3">
          <label>Status:</label>
          <select
            name="jobStatus"
            id="status"
            value={props?.formValues?.jobStatus}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {jobAllStatus?.STATUS?.map((status) => (
              <option value={status.id} key={status.id}>
                {status.description}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="forms-input">
        <label>Job Name:</label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Name here"
          onChange={handleChange}
          value={props?.formValues?.name}
        />
        <span className="error_msg">{formErrors?.name}</span>
      </div>

      <div className="forms-input">
        <label>Description:</label>
        <FroalaEditor
          tag="textarea"
          config={config}
          model={description}
          onModelChange={handleEditor}
        />
        <span className="error_msg">{formErrors?.description}</span>
      </div>

      <div className="forms-input">
        <label>Facility:</label>
        <select
          name="facilityId"
          id="facilityId"
          onChange={handleChange}
          value={props?.formValues?.facilityId}
        >
          <option value="none">Select</option>
          {facilities?.map((facility) => (
            <>
              {facility.address !== "" && (
                <option
                  value={facility.facilitiesId}
                  key={facility.facilitiesId}
                >
                  {facility.address}
                </option>
              )}
            </>
          ))}
        </select>
        <span className="error_msg">{formErrors?.facilities}</span>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Department:</label>
          <select
            name="departmentId"
            id="department"
            onChange={handleChange}
            value={props?.formValues?.departmentId}
          >
            <option value="">Select</option>
            {departments?.map((dept) => (
              <option value={dept.departmentId} key={dept.departmentId}>
                {dept.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Rate:</label>
          <input
            type="text"
            placeholder="Rate here"
            name="rate"
            id="rate"
            value={props?.formValues?.rate}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Rate Type:</label>
          <select
            name="rateTypeId"
            id="rate_type"
            onChange={handleChange}
            value={props?.formValues?.rateTypeId}
          >
            <option value="">Select</option>
            {rateTypes?.map((rate) => (
              <option value={rate.sourceId} key={rate.sourceId}>
                {rate.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Shift Type:</label>
          <select
            name="shiftTypeId"
            id="shift_type"
            onChange={handleChange}
            value={props?.formValues?.shiftTypeId}
          >
            <option value="">Select</option>
            {shiftTypes?.map((shift) => (
              <option value={shift.sourceId} key={shift.sourceId}>
                {shift.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Schedule Type:</label>
          <select
            name="scheduleTypeId"
            id="schedule_type"
            onChange={handleChange}
            value={props?.formValues?.scheduleTypeId}
          >
            <option value="">Select</option>
            {scheduleTypes?.map((schedule) => (
              <option value={schedule.sourceId} key={schedule.sourceId}>
                {schedule.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Employment Type:</label>
          <select
            name="employmentTypeId"
            id="emp_type"
            onChange={handleChange}
            value={props?.formValues?.employmentTypeId}
          >
            <option value="">Select</option>
            {employmentTypes?.map((emp) => (
              <option value={emp.sourceId} key={emp.sourceId}>
                {emp.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Video Interview:</label>
          <select
            name="videoInterviewId"
            id="video_interview"
            onChange={handleChange}
            value={props?.formValues?.videoInterviewId}
          >
            <option value="">Select</option>
            {videoInterviews?.map((video) => (
              <option
                value={video.videoInterviewId}
                key={video.videoInterviewId}
              >
                {video.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Previous Employee:</label>
          <input
            type="text"
            placeholder="Yes / No"
            name="previousEmp"
            id="previousEmp"
            value={props?.formValues?.previousEmp}
            onChange={handleChange}
          />
        </div>
      </div>
      <br />
      <label className="mt-16">
        <span>Maximum Applicants Allowed </span>(leave blank for unlimited)
      </label>
      <div className="forms-input sections mt-10">
        <div className="col-2">
          <label>Total:</label>
          <input
            type="text"
            placeholder="Enter Total"
            name="totalApplication"
            id="totalApplication"
            value={props?.formValues?.totalApplication}
            onChange={handleChange}
          />
        </div>
        <div className="col-2">
          <label>Per Job Board:</label>
          <input
            type="text"
            placeholder="Enter the allowed no."
            name="appPerJobBoard"
            id="per_job_board"
            value={props?.formValues?.appPerJobBoard}
            onChange={handleChange}
          />
        </div>
      </div>
    </AddEditPopup>
  );
};
