import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { InterviewForm } from "./interviewForm";
import { AcceptInterviewForm } from "./acceptInterviewForm";
import { ResendInterviewingForm } from "./resendNotificationForm";
import moment from "moment";
import { DeleteButton } from "../../../../../../ui/button/delete";
import { AcceptButton } from "../../../../../../ui/button/accept";
import { SendButton } from "../../../../../../ui/button/send";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export const InterviewingStatus = ({ selectedSuitCase, applicantId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openInterviewForm, setInterviewForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [interviewList, setInterviewList] = useState([]);
  const [openAcceptInterviewForm, setAcceptInterviewForm] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [openResendForm, setResendForm] = useState(false);
  const [interScheduleId, setInterScheduleId] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [connectionString, setConnection] = useState(null);
  const [flag, setFlag] = useState(""); // To identify the send and cancel action
  const BASEURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getInterviewList();
  }, []);

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(connect);
    connect.on("InterviewingList", (res) => {
      res.map((r) => {
        if (r.applicantId === applicantId) {
          setInterviewList(res);
        }
      });
    });

    await connect.start();
  }, [selectedSuitCase.jobId]);

  const toggleInterviewForm = (open) => {
    setInterviewForm(open);
  };

  const toggleAcceptForm = (open) => {
    setAcceptInterviewForm(open);
  };

  const toggleResendForm = (open) => {
    setResendForm(open);
  };

  const handleNotification = (description, id) => {
    setSubmitting(true);
    const params = {
      description: description,
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantInterviewScheduleId: id,
      applicantId: applicantId,
    };

    setSubmitting(true);

    Api("PUT", `Job/ResendNotificationForRequestInterview`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleResendForm(false);
        } else {
          toast.error(res.data.message);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleAcceptInetrview = (id, slot) => {
    const params = {
      applicantInterviewScheduleId: id,
      acceptedSlot: slot,
    };

    setSubmitting(true);

    Api("POST", `Job/SubmitAcceptInterview`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAcceptForm(false);
          getInterviewList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const getInterviewList = () => {
    Api(
      "GET",
      `Job/GetRequestInterviewList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setInterviewList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleCancelInterview = (description, id) => {
    setSubmitting(true);
    Api(
      "DELETE",
      `Job/DeleteRequestInterview?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}&applicantInterviewScheduleId=${id}&comment=${description}`
    )
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleResendForm(false);
          toast.success(res.data.message);
          getInterviewList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleFormSubmit = () => {
    const params = {
      companyId: selectedSuitCase.companyId,
      applicantId: applicantId,
      jobId: selectedSuitCase.jobId,
      interviewType: formValues.interviewType,
      duration: formValues.duration,
      facilities: formValues.facilities ? formValues.facilities : [],
      addLocation: formValues.addLocation ? formValues.addLocation : {},
      instructions: formValues.notes,
      isRecordMeeting: true,
      interviewers: formValues.interviewers === undefined ? [] : formValues.interviewers,
      interviewersList: formValues.interviewersList === undefined ? [] : formValues.interviewersList,
      selectedSlots: formValues.selectedSlots,
    };

    setSubmitting(true);

    Api("POST", `Job/SaveInterviewForm`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleInterviewForm(false);
          toast.success(res.data.message);
          getInterviewList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <>
      {openInterviewForm && (
        <InterviewForm
          openForm={openInterviewForm}
          toggleForm={toggleInterviewForm}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          formValues={formValues}
          setFormValues={setFormValues}
          handleFormSubmit={handleFormSubmit}
          jobId={selectedSuitCase.jobId}
          companyId={selectedSuitCase.companyId}
          applicantId={applicantId}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isSubmitting={isSubmitting}
        />
      )}

      {openAcceptInterviewForm && (
        <AcceptInterviewForm
          openForm={openAcceptInterviewForm}
          toggleForm={toggleAcceptForm}
          availableSlots={availableSlots}
          handleSubmit={handleAcceptInetrview}
          interScheduleId={interScheduleId}
          applicantId={applicantId}
          isSubmitting={isSubmitting}
        />
      )}

      <button
        className="add-btn manage-btn job video"
        onClick={() => (
          setActiveStep(0),
          toggleInterviewForm(true),
          setFormValues({}),
          setFormErrors({})
        )}
      >
        Request Interview
        <img
          src={require("../../../../../../../assets/images/Vector.png")}
          alt="right-arrow"
          className="right-arrow-btn"
        />
      </button>

      {openResendForm && (
        <ResendInterviewingForm
          handleSubmit={handleNotification}
          toggleForm={toggleResendForm}
          openForm={openResendForm}
          interScheduleId={interScheduleId}
          flag={flag}
          handleCancelInterview={handleCancelInterview}
          applicantId={applicantId}
          isSubmitting={isSubmitting}
        />
      )}

      <div className="profile-data mt-15">
        <div className="inter-container">
          {interviewList?.map((interview, index) => (
            <div className="section mb-10 interview-section" key={index}>
              <div>
                <strong>{interview.interviewType}</strong>
                <span
                  className={
                    interview.interviewScheduleStatus === "Accept"
                      ? "status green-active"
                      : interview.interviewScheduleStatus === "Requested"
                        ? "status red-delete"
                        : "status"
                  }
                >
                  {interview.interviewScheduleStatus === "Accept" ? "Accepted" : interview.interviewScheduleStatus}
                </span>
              </div>
              {interview.interviewScheduleStatus === "Accept" &&
                <span className="scheduled">
                  <label>Scheduled : </label>
                  {interview.interviewSlots?.map(
                    (inter) =>
                      inter.isAcceptedSlots === true &&
                      moment(inter.slots).format("MM/DD/YYYY @ h:mm a")
                  )}
                </span>
              }

              <span className="scheduled"><strong>Interviewers : </strong>{interview.interviewers.join(", ")}</span>

              {interview.interviewScheduleStatus === "Requested" &&
                <span className="scheduled">
                  {interview.interviewSlots?.map(
                    (inter) =>
                      inter.isAcceptedSlots === false &&
                      <div>{moment(inter.slots).format("MM/DD/YYYY @ h:mm a")}<br /></div>
                  )}
                </span>
              }

              <span className="scheduled"><strong>Last Viewed : </strong>{interview.lastViewed}</span>
              <span className="d-flex">
                {interview.interviewScheduleStatus === "Requested" &&
                  interview.interviewScheduleStatus !== "Canceled" && (
                    <AcceptButton
                      onClick={() => (
                        toggleAcceptForm(true),
                        setAvailableSlots(interview.interviewSlots),
                        setInterScheduleId(
                          interview.applicantInterviewScheduleId
                        )
                      )}
                    />
                  )}

                {interview.interviewScheduleStatus !== "Canceled" && (
                  <SendButton
                    onClick={() => (
                      toggleResendForm(true),
                      setInterScheduleId(
                        interview.applicantInterviewScheduleId
                      ),
                      setFlag("send")
                    )}
                  />
                )}

                {interview.interviewScheduleStatus !== "Canceled" && (
                  <DeleteButton
                    onClick={() => (
                      toggleResendForm(true),
                      setInterScheduleId(
                        interview.applicantInterviewScheduleId
                      ),
                      setFlag("cancel")
                    )}
                    title="Cancel"
                  />
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
