import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import {
  AudioIcon,
  FilesIcon,
  ImageIcon,
  VideoIcon,
} from "../../../../../../../icons";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ButtonUI } from "../../../../../../ui/button";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import Api from "../../../../../../../helper/Api";
import { useLocation } from "react-router-dom";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { indigo, red } from "@mui/material/colors";
import { SendOutlined } from "@mui/icons-material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Loader from "./../../../../../Loader";
import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";

const icons = {
  image: <ImageIcon fill="#9EA0A5" width="30px" height="30px" />,
  audio: <AudioIcon fill="#9EA0A5" width="30px" height="30px" />,
  video: <VideoIcon fill="#9EA0A5" width="28px" height="30px" />,
  text: <FilesIcon stroke="#9EA0A5" line="#fff" width="30px" height="30px" />,
  application: (
    <FilesIcon stroke="#9EA0A5" line="#fff" width="30px" height="30px" />
  ),
};

const chatIcons = {
  png: <ImageIcon fill="#fff" width="30px" height="30px" />,
  jpg: <ImageIcon fill="#fff" width="30px" height="30px" />,
  svg: <ImageIcon fill="#fff" width="30px" height="30px" />,
  aud: <AudioIcon fill="#fff" width="30px" height="30px" />,
  mp4: <VideoIcon fill="#fff" width="28px" height="30px" />,
  pdf: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  txt: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  docx: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  csv: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  xls: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  doc: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
};

const MAX_FILE_SIZE = 5120; // 5 MB

export const ChatByApplicant = () => {
  const attachRef = useRef("null");
  const [attachments, setAttachments] = useState([]);
  const [connectionString, setConnection] = useState(null);
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState({});
  const location = useLocation();
  const applicantId = new URLSearchParams(location.search).get("applicantId");
  const jobId = new URLSearchParams(location.search).get("jobId");
  const BASEURL = process.env.REACT_APP_BASE_URL;
  const [isLoading, setLoading] = useState(true);
  const openAttachment = () => {
    attachRef.current.click();
  };

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/chatHub`)
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(connect);
    connect.on("ReceiveMessage", (message, attachment, textAreaId) => {
      setMessages((messages) => [
        ...messages,
        { message: message, attachments: attachment, textAreaId: textAreaId },
      ]);
    });

    await connect.start();
    setConnectionId(connect.connection.connectionId);
    getChatConnection();
  }, []);

  // useEffect(() => {
  //   if (!selectedFile) {
  //     setPreview(undefined);
  //     return;
  //   }
  //   const objectUrl = URL.createObjectURL(selectedFile);
  //   setPreview(objectUrl);
  //   return () => URL.revokeObjectURL(objectUrl);
  // }, [selectedFile]);

  useEffect(() => {
    Api(
      "GET",
      `Chat/GetAllChat?id=${applicantId}&textAreaId=applicant&jobId=${jobId}`
    )
      .then((res) => {
        setMessages(res.data.chatViewModels);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    scrollToBottom("chatScroll");
  }, [messages]);

  const scrollToBottom = (id) => {
    const element = document.getElementById(id);
    element.scrollTo({
      top: element.scrollHeight,
      behavior: "smooth",
    });
  };

  const getChatConnection = () => {
    const args = {
      isCandidate: true,
      applicantId: applicantId,
      jobId: jobId,
    };

    Api("POST", `Chat/GetApplicantSocketConnection`, args)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setConnectionId = (connectionId) => {
    const args = {
      connectionId: connectionId,
      applicantId: applicantId,
      isCandidate: true,
      jobId: jobId,
    };

    Api("POST", `Chat/setConnection`, args)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFiles = (e) => {
    if (e.target.files.length > 0) {
      let files = [...e.target.files];
      const data = [];
      files.map((file) => {
        Math.round(file.size / 1024) < MAX_FILE_SIZE
          ? data.push(file)
          : toast.error("File size must be smaller than 5 MB");
      });

      setAttachments([...attachments, ...data]);
    } else {
      Math.round(e.target.files[0].size / 1024) < MAX_FILE_SIZE
        ? setAttachments([...attachments, ...e.target.files])
        : toast.error("File size must be smaller than 5 MB");
    }
  };

  const removeAttachment = (index) => {
    const updateAttachments = [...attachments];
    updateAttachments.splice(index, 1);
    setAttachments([...updateAttachments]);
  };

  const uploadAttachment = (attachment) => {
    const formData = new FormData();
    formData.append("applicantId", applicantId);
    formData.append("attachments", attachment);
    Api("POST", `Chat/UploadAttachments`, formData)
      .then((res) => {
        setAttachments([]);
      })
      .catch((err) => console.log(err));
  };

  const sendMessage = () => {
    if (attachments.length > 0) {
      attachments.map((attachment) => {
        sendMessageToHR(
          "https://talentats.s3.us-east-1.amazonaws.com/talentats/Chat/" +
            attachment.name
        );
        uploadAttachment(attachment);
      });
    }

    if (inputText !== "") sendMessageToHR();
  };

  const sendMessageToHR = async (attachment = "") => {
    const paramObj = {
      message: inputText,
      id: applicantId,
      attachments: attachment,
    };

    setInputText("");
    await connectionString.send(
      "SendApplicantPrivateMessage",
      paramObj,
      "applicant",
      data.connectionId,
      jobId
    );
    setAttachments([]);
  };

  const downloadFile = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, url.split("/").at(-1));
      });
  };

  return (
    <div>
      <div className="applicant-chat">
        <div className="tab-container">
          <input
            type="file"
            multiple
            ref={attachRef}
            hidden
            onChange={handleFiles}
          />
          <div className="chat-container applicant" id="chatScroll">
            <span className="heading-label font-blue">
              Chat Messages ({messages.length})
            </span>
            {isLoading ? (
              <Loader inShow={true} />
            ) : messages.length > 0 ? (
              <>
                {messages.map((message, index) => (
                  <div
                    className="message-chat-container p-0-16"
                    style={{
                      justifyContent:
                        message.textAreaId === "applicant"
                          ? "flex-end"
                          : "flex-start",
                      position: "relative",
                      paddingRight: !!message.textAreaId && "8px",
                      paddingLeft: message.textAreaId !== "applicant" && "8px",
                    }}
                    key={new Date() * Math.random()}
                  >
                    <div
                      className="message-chat"
                      style={{
                        backgroundColor:
                          message.textAreaId === "applicant"
                            ? "#CCDCFF"
                            : "#F2F6FF",
                        borderRadius:
                          message.textAreaId === "system"
                            ? "0px 8px 8px 8px"
                            : "8px 0px 8px 8px",
                      }}
                    >
                      <span
                        className={
                          message.textAreaId === "applicant"
                            ? "sender-label"
                            : "sender-label applicant"
                        }
                      >
                        {message.textAreaId === "applicant"
                          ? "You"
                          : message.userName}
                      </span>
                      <div className="message-text">
                        {message?.attachments?.length > 0 ? (
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ width: "220px" }}
                          >
                            <div className="attach-icon">
                              {
                                chatIcons[
                                  message?.attachments[0]?.split(".").at(-1)
                                ]
                              }
                            </div>
                            <div className="ml-10 attach-data">
                              <span>
                                {message?.attachments[0]
                                  .split("/")
                                  .at(-1)
                                  .substring(0, 25) + "..."}
                              </span>
                            </div>
                            <Tooltip title="Download">
                              <IconButton
                                onClick={() =>
                                  downloadFile(message?.attachments[0])
                                }
                              >
                                <FileDownloadOutlinedIcon
                                  sx={{ color: indigo[500] }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : (
                          message.message
                        )}
                      </div>
                      <div className="chat-footer">
                        <div className="chat-footer-detail">
                          <span className="chat-footer-labels">
                            {moment(message.createdAt).format("MM/DD/YYYY")}
                          </span>
                          <div className="sm-dot-div" />
                          <span className="chat-footer-labels">
                            {moment(message.createdAt).format("LT")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="no-message">
                <QuestionAnswerOutlinedIcon sx={{ fontSize: 100 }} />
                <span>
                  Start your chat with our representative by sending your first
                  message !
                </span>
              </div>
            )}
          </div>

          <div className="border-bottom"></div>

          <div className="chat-text">
            <input
              type="text"
              placeholder="Type your message here"
              name="inputText"
              value={inputText}
              onChange={(input) => {
                setInputText(input.target.value);
              }}
            />
            <Tooltip title="Attachment">
              <IconButton onClick={openAttachment}>
                <AttachFileOutlinedIcon sx={{ color: indigo[500] }} />
              </IconButton>
            </Tooltip>

            <ButtonUI
              radius="4px"
              height="38px"
              width="40px"
              onClick={sendMessage}
            >
              <IconButton>
                <SendOutlined sx={{ color: indigo[50] }} />
              </IconButton>
            </ButtonUI>
          </div>

          <div className="attachment-container">
            {attachments?.map((attachment, index) => (
              <div className="attachemt-item">
                <Tooltip title={attachment.name}>
                  <div className="attachment">
                    <IconButton>
                      {icons[attachment.type.split("/")[0]]}
                    </IconButton>
                    <span className="font-14">
                      {attachment.name.substring(0, 8) + "..."}
                    </span>
                    <IconButton onClick={() => removeAttachment(index)}>
                      <CloseOutlinedIcon sx={{ color: red[500] }} />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
